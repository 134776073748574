import React from 'react';
import ReactECharts from 'echarts-for-react';

const StackBarChart = ({ domainTypeData, domains }) => {
    // Initialize data structure to hold total correct and total questions for each domain and question type
    const dataMap = {};
    domains.forEach(domain => {
        dataMap[domain] = {
            MCQ: { totalCorrect: 0, totalQuestions: 0 },
            TRUEFALSE: { totalCorrect: 0, totalQuestions: 0 },
            OPENENDED: { totalCorrect: 0, totalQuestions: 0 },
            "SCENARIO-FOLLOWUPS": { totalCorrect: 0, totalQuestions: 0 },
        };
    });

    // Populate dataMap with values from domainTypeData
    Object.keys(domainTypeData).forEach(domain => {
        Object.keys(domainTypeData[domain]).forEach(questionType => {
            const data = domainTypeData[domain][questionType];
            dataMap[domain][questionType].totalCorrect += data.correct;
            dataMap[domain][questionType].totalQuestions += data.total;
        });
    });

    console.log(dataMap)
    let colors = {
        'MCQ':'#485FB5',
        'TRUEFALSE':'#009DD9',
        'OPENENDED':'#6A5286',
        'SCENARIO-FOLLOWUPS':'#F07F09'
    }

    let names = {
        'MCQ':'MCQs',
        'TRUEFALSE':'TFQs',
        'OPENENDED':'OEQs',
        'SCENARIO-FOLLOWUPS':'SQs'
    }

    // Prepare data for stacked bar chart
    const xAxisData = domains;
    const seriesData = ['MCQ', 'TRUEFALSE', 'OPENENDED', 'SCENARIO-FOLLOWUPS'].map(questionType => ({
            name: names[questionType],
            type: 'bar',
            stack: 'percentage',
            itemStyle: {
                color: colors[questionType],
                borderColor: colors[questionType]
            },
            data: xAxisData.map(domain => {
                const { totalCorrect, totalQuestions } = dataMap[domain][questionType];
                const percentage = totalQuestions > 0 ? (totalCorrect / totalQuestions) * 100 : 0;
                return percentage.toFixed(1);
            }),
    }));

    const option = {
        title: {
            text: "Question Type % Accuracy Breakdown by Domain",
            position: "left",
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        toolbox: {
            show: true,
            feature: {
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: function(val){
                let domainIndex = parseInt(val[0].name.split(" ")[1]) -1
                // return `${domains[domainIndex]} <br/>${val[0].seriesName}: ${val[0].data}%<br/>${val[1].seriesName}: ${val[1].data}%`
                let tooltip = `${domains[domainIndex]}`
                for(var i=0; i<val.length; i++){
                    tooltip +=`<br/>${val[i].seriesName}: ${val[i].data}%`
                }
                return tooltip
            },
        },
        legend: {
            data: ['MCQs', 'TFQs', 'OEQs', 'SQs'],
            top: "bottom"
        },
        xAxis: {
            type: 'category',
            data: domains.map((domain, index)=> "Domain "+(index+1)),
            axisLabel: {
                rotate: 90, // Rotate the label by 90 degrees
            }
        },
        grid: {
            bottom: 100
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
            },
        },
        series: seriesData,
    };

    return (
        <div className='border rounded border-2'>
            <ReactECharts option={option} style={{ height: '300px' }} />
        </div>
    )
};

export default StackBarChart;
