import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDashboard, fetchResult } from '../helper/requests';

export const fetchDashboardAsync = createAsyncThunk(
    'dashboard/fetchDashboard',
    async (data) => {
        return await fetchDashboard(data);
    }
);

export const fetchResultAsync = createAsyncThunk(
    'dashboard/fetchResult',
    async (data) => {
        return await fetchResult(data);
    }
);

const initialState = {
    dashboard: null,
    result: null,
    status: 'idle',
    error: null
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        fetchDashboardStart: state => {
            state.status = 'loading';
        },
        fetchDashboardSuccess: (state, action) => {
            state.status = 'succeeded';
            state.dashboard = action.payload;
        },
        fetchDashboardFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        fetchResultStart: state => {
            state.status = 'loading';
        },
        fetchResultSuccess: (state, action) => {
            state.status = 'succeeded';
            state.result = action.payload;
        },
        fetchResultFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
    extraReducers: builder => {
        builder
        .addCase(fetchDashboardAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchDashboardAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.dashboard = action.payload;
        })
        .addCase(fetchDashboardAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchResultAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchResultAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.result = action.payload;
        })
        .addCase(fetchResultAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export const { 
    fetchDashboardStart, 
    fetchDashboardSuccess, 
    fetchDashboardFailure, 
    fetchResultStart, 
    fetchResultSuccess, 
    fetchResultFailure
} = dashboardSlice.actions;

export const selectDashboard = (state) => state.dashboard.dashboard;
export const selectResult = (state) => state.dashboard.result;
export const selectStatus = (state) => state.dashboard.status;
export const selectError = (state) => state.dashboard.error;

export default dashboardSlice.reducer;
