// codeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { adminCodeStatistics, getCodesWithFilter } from '../helper/requests';

let accessLevels = [
    {type: "Premium", key: "premium", allowedQuantity: 100}, 
    {type: "Premium Pro", key: "premium_pro", allowedQuantity: 200}, 
    {type: "Premium Pro+", key: "premium_pro_plus", allowedQuantity: -1}
]

const allTypes = ["MCQ", "TRUEFALSE", "OPENENDED", "SCENARIO", "ALL"]

const access = {
    premium: {
        difficulty: {
            options: [
                {text: "Foundation", key:"FOUNDATION", disabled: true},
                {text: "Intermediate", key:"INTERMEDIATE", disabled: false},
                {text: "Advanced", key:"ADVANCED", disabled: true},
                {text: "All", key:"ALL", disabled: true},
            ],
            selection: "INTERMEDIATE"
        },
        questionTypes: {
            options: [
                {text: "Multiple Choice Questions", key:"MCQ", quantity: 0, disabled: false},
                {text: "True & False", key:"TRUEFALSE", quantity: 0, disabled: true},
                {text: "Open Ended", key:"OPENENDED", quantity: 0, disabled: true},
                {text: "Scenario Based", key:"SCENARIO", quantity: 0, disabled: true},
                {text: "All", key:"ALL", quantity: 0, disabled: true},
            ],
            selection: ["MCQ"]
        }
    },
    premium_pro: {
        difficulty: {
            options: [
                {text: "Foundation", key:"FOUNDATION", disabled: false},
                {text: "Intermediate", key:"INTERMEDIATE", disabled: false},
                {text: "Advanced", key:"ADVANCED", disabled: false},
                {text: "All", key:"ALL", disabled: false},
            ],
            selection: "INTERMEDIATE"
        },
        questionTypes: {
            options: [
                {text: "Multiple Choice Questions", key:"MCQ", quantity: 0, disabled: false},
                {text: "True & False", key:"TRUEFALSE", quantity: 0, disabled: false},
                {text: "Open Ended", key:"OPENENDED", quantity: 0, disabled: false},
                {text: "Scenario Based", key:"SCENARIO", quantity: 0, disabled: false},
                {text: "All", key:"ALL", quantity: 0, disabled: false},
            ],
            selection: ["MCQ", "TRUEFALSE", "OPENENDED", "SCENARIO", "ALL"]
        }
    },
    premium_pro_plus: {
        difficulty: {
            options: [
                {text: "Foundation", key:"FOUNDATION", disabled: false},
                {text: "Intermediate", key:"INTERMEDIATE", disabled: false},
                {text: "Advanced", key:"ADVANCED", disabled: false},
                {text: "All", key:"ALL", disabled: false},
            ],
            selection: "INTERMEDIATE"
        },
        questionTypes: {
            options: [
                {text: "Multiple Choice Questions", key:"MCQ", quantity: 0, disabled: false},
                {text: "True & False", key:"TRUEFALSE", quantity: 0, disabled: false},
                {text: "Open Ended", key:"OPENENDED", quantity: 0, disabled: false},
                {text: "Scenario Based", key:"SCENARIO", quantity: 0, disabled: false},
                {text: "All", key:"ALL", disabled: false},
            ],
            selection: ["MCQ", "TRUEFALSE", "OPENENDED", "SCENARIO", "ALL"]
        }
    }
}

// Async thunk to fetch access object from the backend based on input code
export const validateCodeAsync = createAsyncThunk(
  'code/validateCode',
  async (inputCode, thunkAPI) => {
    try {
      const response = await axios.post('/api/access', { code: inputCode });
      return response.data.access;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


// Async thunk to fetch access object from the backend based on input code


export const adminCodeStatisticsAsync = createAsyncThunk(
    'dashboard/adminCodeStatistics',
    async (params) => {
        return await adminCodeStatistics(params);
    }
);


export const getCodesWithFilterAsync = createAsyncThunk(
    'dashboard/getCodesWithFilter',
    async (params) => {
        return await getCodesWithFilter(params);
    }
);





let payload = localStorage.getItem("access")
let accessDefault = null;
let accessLevelDefault = null;
let selectedDifficultyDefault = null;
let metaDataDefault =  null;
let selectedQuestionTypesDefault = [];
let selectedQuestionQuantitesDefault = null;
if(payload){
    payload = JSON.parse(payload);
    if(payload.token){
        axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
        accessDefault = access[payload.type]
        let accessLevel = accessLevels.find(item=>payload.type==item.key)
        accessLevelDefault=accessLevel 
        selectedDifficultyDefault= access[payload.type].difficulty.selection;
        selectedQuestionTypesDefault= access[payload.type].questionTypes.selection;
        let selectedTypes = access[payload.type].questionTypes.selection
        let selectedTypesLength = selectedTypes.length
        if(selectedTypes.indexOf("ALL")){
            selectedTypesLength--
            if(selectedTypesLength == 0){
                selectedTypesLength = 1
            }
        }
        metaDataDefault = payload
        selectedQuestionQuantitesDefault = access[payload.type].questionTypes.selection.reduce((acc, cur)=>{acc[cur]=accessLevel.allowedQuantity/selectedTypesLength; return acc}, {})
    }else{
        localStorage.removeItem("access")
    }
}

const initialState = {
  access: accessDefault,
  accessLevel: accessLevelDefault,
  selectedDifficulty: selectedDifficultyDefault,
  metaData: metaDataDefault,
  selectedQuestionTypes: selectedQuestionTypesDefault,
  selectedQuestionQuantites:selectedQuestionQuantitesDefault,
  statistics: null,
  questions: null,
  answers: null,
  filtered: [],
  status: 'idle',
  error: null,
};

const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    setAccessData: (state, action) => {
        state.access = access[action.payload.type]
        let accessLevel = accessLevels.find(item=>action.payload.type==item.key)
        state.accessLevel=accessLevel 
        state.selectedDifficulty= access[action.payload.type].difficulty.selection;
        state.selectedQuestionTypes= access[action.payload.type].questionTypes.selection;
        let selectedTypes = access[action.payload.type].questionTypes.selection
        let selectedTypesLength = selectedTypes.length
        if(selectedTypes.indexOf("ALL")){
            selectedTypesLength--
            if(selectedTypesLength == 0){
                selectedTypesLength = 1
            }
        }
        state.metaData = action.payload
        state.selectedQuestionQuantites = access[action.payload.type].questionTypes.selection.reduce((acc, cur)=>{acc[cur]=accessLevel.allowedQuantity/selectedTypesLength; return acc}, {})
    },
    setStatistics: (state, action) => {
      state.statistics = action.payload;
    },
    setSelectedDifficulty: (state, action) => {
      state.selectedDifficulty = action.payload;
    },
    toggleSelectedQuestionType: (state, action) => {
        const type = action.payload;
        let tempTypes = state.selectedQuestionTypes.slice()
        const index = tempTypes.indexOf(type);
        if (index === -1) {
            if(type=="ALL"){
                allTypes.map(val => {
                    tempTypes.push(val)
                    state.selectedQuestionTypes = tempTypes;
                    state.selectedQuestionQuantites[val] = 0;
                })
            }else{
                tempTypes.push(type)
                state.selectedQuestionTypes = tempTypes;
                state.selectedQuestionQuantites[type] = 0;
            }
        } else {
            if(type=="ALL"){
                state.selectedQuestionTypes = [];
                allTypes.map(val => {
                    delete state.selectedQuestionQuantites[val]
                })
            }else{
                tempTypes.splice(index, 1);
                const ind = tempTypes.indexOf("ALL");
                if(ind > -1){
                    tempTypes.splice(ind, 1);
                }
                state.selectedQuestionTypes = tempTypes;
                delete state.selectedQuestionQuantites[type]
                delete state.selectedQuestionQuantites["ALL"]
            }
        }
    },
    setQuestionsQuantity: (state, action) => {
        state.selectedQuestionQuantites[action.type] = action.value
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateCodeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateCodeAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.access = action.payload;
      })
      .addCase(validateCodeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(adminCodeStatisticsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(adminCodeStatisticsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.statistics = action.payload.codes;
        state.questions = action.payload.questions;
        state.answers = action.payload.answers;
      })
      .addCase(adminCodeStatisticsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getCodesWithFilterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCodesWithFilterAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.filtered = action.payload;
      })
      .addCase(getCodesWithFilterAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setSelectedDifficulty, toggleSelectedQuestionType, setQuestionsQuantity, setAccessData, setStatistics } = codeSlice.actions;

export const selectAccess = (state) => state.code.access;
export const selectStatistics = (state) => state.code.statistics;
export const selectQuestionStats = (state) => state.code.questions;
export const selectAnswerStats = (state) => state.code.answers;
export const selectFiltered = (state) => state.code.filtered;
export const selectAccessLevel = (state) => state.code.accessLevel;
export const selectSelectedDifficulty = (state) => state.code.selectedDifficulty;
export const selectSelectedQuestionTypes = (state) => state.code.selectedQuestionTypes;
export const selectSelectedQuestionQuantites = (state) => state.code.selectedQuestionQuantites;
export const selectMetaData = (state) => state.code.metaData;
export const selectStatus = (state) => state.code.status;
export const selectError = (state) => state.code.error;

export default codeSlice.reducer;

