import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQuizById } from '../helper/requests';

export const fetchQuizByIdAsync = createAsyncThunk(
    'quiz/fetchQuizById',
    async (quizId) => {
        return await fetchQuizById(quizId);
    }
);

const initialState = {
    quiz: null,
    status: 'idle',
    error: null
};

const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        fetchQuizByIdStart: state => {
            state.status = 'loading';
        },
        fetchQuizByIdSuccess: (state, action) => {
            state.status = 'succeeded';
            state.quiz = action.payload;
        },
        fetchQuizByIdFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        setFlag: (state, action) => {
            state.quiz.questionIds[action.payload.index].isFlagged = action.payload.value
        },
        setUpVote: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVoted = true
            state.quiz.questionIds[action.payload.index].hasDownVoted = false
        },
        setDownVote: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVoted = false
            state.quiz.questionIds[action.payload.index].hasDownVoted = true
        },
        setUpVoteReasoning: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVotedReasoning = true
            state.quiz.questionIds[action.payload.index].hasDownVotedReasoning = false
        },
        setDownVoteReasoning: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVotedReasoning = false
            state.quiz.questionIds[action.payload.index].hasDownVotedReasoning = true
        },
        setAnswer: (state, action) => {
            console.log("Answer Index", action)
            state.quiz.questionIds[action.payload.index].userAnswer = action.payload.value
        }
    },
    extraReducers: builder => {
        builder
        .addCase(fetchQuizByIdAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchQuizByIdAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.quiz = action.payload;
        })
        .addCase(fetchQuizByIdAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export const { 
    fetchQuizByIdStart, 
    fetchQuizByIdSuccess, 
    fetchQuizByIdFailure, 
    setFlag, 
    setUpVote, 
    setDownVote,
    setUpVoteReasoning,
    setDownVoteReasoning, 
    setAnswer 
} = quizSlice.actions;

export const selectQuiz = (state) => state.quiz.quiz;
export const selectStatus = (state) => state.quiz.status;
export const selectError = (state) => state.quiz.error;

export default quizSlice.reducer;
