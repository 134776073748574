import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatus, selectError } from '../../redux/quizSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchResultAsync, selectResult } from '../../redux/dashboardSlice';
import { selectMetaData } from '../../redux/codeSlice';
import ResultGraphs from '../result/ResultGraphs';

const Result = () => {
    const dispatch = useDispatch();
    const { quizId } = useParams();
    const status = useSelector(selectStatus)
    const error = useSelector(selectError)

    const metaData = useSelector(selectMetaData)
    const graphsResult = useSelector(selectResult)
  
    useEffect(() => {
        if(metaData && metaData.code){
            const code = metaData.code
            const subject = metaData.subject
            dispatch(fetchResultAsync({code, subject, quizId}));
        }
    }, [dispatch, metaData]);

    return (
        <>
            {
                graphsResult && <ResultGraphs result={graphsResult}/>
            }
        </>
    );
};

export default Result;
