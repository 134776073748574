// src/components/PrivateRoute.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAdmin } from '../redux/userSlice';

const PrivateAdminRoute = (props) => {
    const navigate = useNavigate()
    const admin = useSelector(selectAdmin);

    useEffect(()=>{
        if(!admin){
            navigate('/admin/login')
        }
    }, [admin])

    return props.children;
};

export default PrivateAdminRoute;
