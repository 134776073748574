// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import QuizSelection from './pages/QuizSelection';
import UserInfo from './pages/UserInfo';
import QuizScreen from './pages/QuizScreen';
import PasscodeEntry from './pages/PasscodeEntry';
import QuizCustomise from './pages/QuizCustomise';
import QuizResult from './pages/QuizResult';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import QuizScreenNew from './pages/QuizScreenNew';
import Login from './pages/admin/Login';
import PrivateAdminRoute from './components/PrivateAdminRoute';
import Home from './pages/admin/Home';
import Users from './pages/admin/Users';
import Questions from './pages/admin/Questions';


function App() {
  console.log("App Starts")
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/login" element={<PasscodeEntry />} />
      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin/home" element={<PrivateAdminRoute><Home /></PrivateAdminRoute>} />
      <Route path="/admin" element={<PrivateAdminRoute><Home /></PrivateAdminRoute>} />
      <Route path="/admin/access-codes" element={<PrivateAdminRoute><Users /></PrivateAdminRoute>} />
      <Route path="/admin/questions" element={<PrivateAdminRoute><Questions /></PrivateAdminRoute>} />
      <Route path="/userinfo" element={<PrivateRoute><UserInfo /></PrivateRoute>} />
      <Route path="/quiz-list" exact element={<PrivateRoute><QuizSelection /></PrivateRoute>}  />
      <Route path="/quiz-customize" element={<PrivateRoute><QuizCustomise /></PrivateRoute>} />
      <Route path="/quiz-old/:quizId" element={<PrivateRoute><QuizScreen /></PrivateRoute>} />
      <Route path="/quiz/:quizId" element={<PrivateRoute><QuizScreenNew /></PrivateRoute>} />
      <Route path="/result/:quizId" element={<PrivateRoute><QuizResult /></PrivateRoute>} />
    </Routes>
  );
}

export default App;
