import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Form, Alert, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, CardFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import { getCodesWithFilterAsync, selectFiltered } from '../../redux/codeSlice';
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { createAccessCodeRequest, deleteAccessCodeRequest, updateAccessCodeRequest } from '../../helper/requests';
import CustomPagination from '../../components/CustomPagination';

const initialState = {
    type: 'premium_pro_plus',
    code: '',
    title: '',
    description: '',
    subject: 'CCP',
    domain: { id: '', title: '' },
    task: '',
    topic: '',
    questionCount: '175',
    timeAllowed: '',
    status: 1,
}

const Users = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const colors = {
        "premium": "info",
        "premium_pro": "primary",
        "premium_pro_plus": "success",
    }
    const questionLimits = {
        "premium": 100,
        "premium_pro": 150,
        "premium_pro_plus": 175,
    }
    const filteredCodes = useSelector(selectFiltered);

    const [selectedAccessCode, setSelectedAccessCode] = useState(null);

    const [modalCreate, setModalCreate] = useState(false);
    const toggleCreate = () => setModalCreate(!modalCreate);

    const [modalDelete, setModalDelete] = useState(false);
    const toggleDelete = () => setModalDelete(!modalDelete);

    const [modalUpdate, setModalUpdate] = useState(false);
    const toggleUpdate = () => setModalUpdate(!modalUpdate);

    const [params, setParams] = useState({ subject: "CCP" });

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const [formData, setFormData] = useState({...initialState});
    const [filteredData, setFilteredData] = useState([]);

    // New state variables for pagination, filtering, and sorting
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [filters, setFilters] = useState({ code: '', subject: '' });
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const selectAndToggle = (key, code) => {
        if(key === "create"){
            setSelectedAccessCode(initialState);
            toggleCreate();
        } else {
            setSelectedAccessCode(code);
            if(key === "update"){
                setFormData(code);
                toggleUpdate();
            } else {
                toggleDelete();
            }
        }
    }

    const validateForm = () => {
        const newErrors = {};
        if (!formData.type) newErrors.type = 'Type is required';
        if (!formData.code) newErrors.code = 'Code is required';
        if (!formData.subject) newErrors.subject = 'Subject is required';
        if (!formData.questionCount) newErrors.questionCount = 'Question count is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === "type"){
            setFormData({ ...formData, [name]: value, questionCount: questionLimits[value], timeAllowed: questionLimits[value] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const createAccessCode = async () => {
        if (!validateForm()) return;

        try {
            await createAccessCodeRequest(formData);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                dispatch(getCodesWithFilterAsync(params));
                toggleCreate();
            }, 2000);
        } catch (error) {
            setErrors({ apiError: error.response?.data?.message || 'An error occurred' });
        }
    };

    const deleteAccessCode = async () => {
        if (!selectedAccessCode) return;

        try {
            await deleteAccessCodeRequest(selectedAccessCode._id);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                dispatch(getCodesWithFilterAsync(params));
                toggleDelete();
            }, 2000);
        } catch (error) {
            setErrors({ apiError: error.response?.data?.message || 'An error occurred' });
        }
    };

    const updateAccessCode = async () => {
        if (!formData) return;
        try {
            delete formData.progress;
        } catch(e) {}
        try {
            await updateAccessCodeRequest(selectedAccessCode._id, formData);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                dispatch(getCodesWithFilterAsync(params));
                toggleUpdate();
            }, 2000);
        } catch (error) {
            setErrors({ apiError: error.response?.data?.message || 'An error occurred' });
        }
    };

    useEffect(()=>{
        dispatch(getCodesWithFilterAsync(params));
    }, [params]);

    useEffect(() => {
        applyFiltersAndSorting();
    }, [filters, sortConfig, currentPage, itemsPerPage, filteredCodes]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
        setCurrentPage(1); // Reset to first page on filter change
    };

    const handleSort = (key) => {
        // let direction = 'ascending';
        // if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        //     direction = 'descending';
        // }
        // setSortConfig({ key, direction });
    };

    const applyFiltersAndSorting = () => {
        if(filteredCodes && filteredCodes.length){
            let filtered = filteredCodes;

            // Apply filters
            Object.keys(filters).forEach(key => {
                if (filters[key]) {
                    filtered = filtered.filter(item =>
                        item[key].toString().toLowerCase().includes(filters[key].toLowerCase())
                    );
                }
            });

            // Apply sorting
            if (sortConfig !== null && sortConfig.key !== '') {
                filtered = filtered.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
            console.log("Filtered", filtered)
            setFilteredData(filtered);
        }else{
            setFilteredData([])
        }
    };
    const [indexOfLastItem, setIndexOfLastItem] =  useState(0);
    const [indexOfFirstItem, setIndexOfFirstItem] =  useState(0);
    const [currentItems, setCurrentItems] =  useState([]);
    const [totalPages, setTotalPages] =  useState(0);
    useEffect(()=>{
        const _indexOfLastItem = currentPage * itemsPerPage;
        const _indexOfFirstItem = _indexOfLastItem - itemsPerPage;
        const _currentItems = filteredData.slice(_indexOfFirstItem, _indexOfLastItem);
        const _totalPages = Math.ceil(filteredData.length / itemsPerPage);
        setIndexOfLastItem(_indexOfLastItem);
        setIndexOfFirstItem(_indexOfFirstItem);
        setCurrentItems(_currentItems);
        setTotalPages(_totalPages)
        console.log("CurrentItems", _currentItems)
    }, [filteredData, currentPage, itemsPerPage])

    return (
        <>
            <AdminLayout>
                <>
                    <Row className='mb-0' style={{overflow: 'hidden'}}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="filterCode" className="mr-sm-2">Code</Label>
                                                <Input type="text" name="code" id="filterCode" placeholder="Code" value={filters.code} onChange={handleFilterChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="filterSubject" className="mr-sm-2">Subject</Label>
                                                <Input type="text" name="subject" id="filterSubject" placeholder="Subject" value={filters.subject} onChange={handleFilterChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="itemsPerPageSelect" className="mr-sm-2">Items per page</Label>
                                                <Input type="select" id="itemsPerPageSelect" value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                        </Col>
                                        <Col style={{textAlign: 'right', paddingTop: '30px'}}>
                                            <Button color="primary" onClick={()=>selectAndToggle("create", null)}>Create Code</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div style={{maxHeight: 'calc(100vh - 280px)', overflowY: 'auto'}}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th onClick={() => handleSort('code')}>Code</th>
                                                    <th onClick={() => handleSort('subject')}>Subject</th>
                                                    <th>Access</th>
                                                    <th onClick={() => handleSort('questionCount')}>Daily Limit</th>
                                                    <th onClick={() => handleSort('createdAt')}>Created on</th>
                                                    <th>Active User</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((accessCode, index) => (
                                                    <tr key={"codes_"+index}>
                                                        <td>{(currentPage-1)*itemsPerPage + (index + 1)}</td>
                                                        <td>{accessCode.code}</td>
                                                        <td>{accessCode.subject}</td>
                                                        <td><Badge color={colors[accessCode.type]}>{accessCode.type.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}</Badge></td>
                                                        <td>{accessCode.questionCount}</td>
                                                        <td>{accessCode.createdAt ? new Date(accessCode.createdAt).toDateString() : new Date().toDateString()}</td>
                                                        <td>{accessCode.status === 2 ? <Badge color="danger">Archived</Badge> : accessCode.progress ? <Badge color="primary">Active</Badge> : <Badge color="warning">Inactive</Badge>}</td>
                                                        <td>
                                                            <RiEdit2Fill onClick={() => selectAndToggle("update", accessCode)} size={18} style={{cursor: "pointer", color: "green"}} />{' '}
                                                            <MdDelete onClick={() => selectAndToggle("delete", accessCode)} size={18} style={{cursor: "pointer", color: "red"}} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <CustomPagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={setCurrentPage}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    {/* Create Modal */}
                    <Modal isOpen={modalCreate} toggle={toggleCreate}>
                        <ModalHeader toggle={toggleCreate}>Create Access Code</ModalHeader>
                        <ModalBody>
                            <Form>
                                {errors.apiError && <Alert color="danger">{errors.apiError}</Alert>}
                                {success && <Alert color="success">Access code created successfully!</Alert>}
                                <FormGroup>
                                    <Label for="type">Type</Label>
                                    <Input type="select" name="type" id="type" value={formData.type} onChange={handleChange}>
                                        <option value="">Select Type</option>
                                        <option value="premium">Premium</option>
                                        <option value="premium_pro">Premium Pro</option>
                                        <option value="premium_pro_plus">Premium Pro Plus</option>
                                    </Input>
                                    {errors.type && <Alert color="danger">{errors.type}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="code">Code</Label>
                                    <Input type="text" name="code" id="code" value={formData.code} onChange={handleChange} />
                                    {errors.code && <Alert color="danger">{errors.code}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="subject">Subject</Label>
                                    <Input type="select" name="subject" id="subject" value={formData.subject} onChange={handleChange}>
                                        <option value="">Select Subject</option>
                                        <option value="CCP">CCP</option>
                                        <option value="CCA">CCA</option>
                                        <option value="NIST">NIST</option>
                                    </Input>
                                    {errors.subject && <Alert color="danger">{errors.subject}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="questionCount">Daily Limit</Label>
                                    <Input type="number" name="questionCount" id="questionCount" value={formData.questionCount} disabled />
                                    {errors.questionCount && <Alert color="danger">{errors.questionCount}</Alert>}
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={createAccessCode}>Create</Button>{' '}
                            <Button color="secondary" onClick={toggleCreate}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    {/* Delete Modal */}
                    <Modal isOpen={modalDelete} toggle={toggleDelete}>
                        <ModalHeader toggle={toggleDelete}>Delete Access Code</ModalHeader>
                        <ModalBody>Are you sure you want to delete this access code?</ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={deleteAccessCode}>Delete</Button>{' '}
                            <Button color="secondary" onClick={toggleDelete}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    {/* Update Modal */}
                    <Modal isOpen={modalUpdate} toggle={toggleUpdate}>
                        <ModalHeader toggle={toggleUpdate}>Update Access Code</ModalHeader>
                        <ModalBody>
                            <Form>
                                {errors.apiError && <Alert color="danger">{errors.apiError}</Alert>}
                                {success && <Alert color="success">Access code updated successfully!</Alert>}
                                <FormGroup>
                                    <Label for="type">Type</Label>
                                    <Input type="select" name="type" id="type" value={formData.type} onChange={handleChange}>
                                        <option value="">Select Type</option>
                                        <option value="premium">Premium</option>
                                        <option value="premium_pro">Premium Pro</option>
                                        <option value="premium_pro_plus">Premium Pro Plus</option>
                                    </Input>
                                    {errors.type && <Alert color="danger">{errors.type}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="code">Code</Label>
                                    <Input type="text" name="code" id="code" value={formData.code} onChange={handleChange} />
                                    {errors.code && <Alert color="danger">{errors.code}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="subject">Subject</Label>
                                    <Input type="select" name="subject" id="subject" value={formData.subject} onChange={handleChange}>
                                        <option value="">Select Subject</option>
                                        <option value="CCP">CCP</option>
                                        <option value="CCA">CCA</option>
                                        <option value="NIST">NIST</option>
                                    </Input>
                                    {errors.subject && <Alert color="danger">{errors.subject}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="questionCount">Daily Limit</Label>
                                    <Input type="number" name="questionCount" id="questionCount" value={formData.questionCount} onChange={handleChange} />
                                    {errors.questionCount && <Alert color="danger">{errors.questionCount}</Alert>}
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={updateAccessCode}>Update</Button>{' '}
                            <Button color="secondary" onClick={toggleUpdate}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </>
            </AdminLayout>
        </>
    );
};

export default Users;
