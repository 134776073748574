import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Form, Alert, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, CardFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import { adminCodeStatisticsAsync, getCodesWithFilterAsync, selectFiltered, selectStatistics } from '../../redux/codeSlice';
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { deleteQuestionRequest, updateQuestionRequest } from '../../helper/requests';
import CustomPagination from '../../components/CustomPagination';
import { fetchFilteredQuestionsAsync, selectQuestionsData } from '../../redux/questionSlice';


const Questions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const colors = {
        "FOUNDATION": "info",
        "INTERMEDIATE": "primary",
        "ADVANCE": "success",
    }
    const questionTypes = {
        "MCQ": "MCQ",
        "TRUEFALSE": "TFQ",
        "OPENENDED": "OEQ",
        "SCENARIO": "SBQ",
    }
    const {page, limit, totalRecords, totalPages, questions} = useSelector(selectQuestionsData);

    const [selected, setSelected] = useState(null);

    const [modalDelete, setModalDelete] = useState(false);
    const toggleDelete = () => setModalDelete(!modalDelete);

    const [modalUpdate, setModalUpdate] = useState(false);
    const toggleUpdate = () => setModalUpdate(!modalUpdate);

    const [params, setParams] = useState({ subject: "CCP", page: 1, limit: 5 });

    const [formData, setFormData] = useState({});

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({ difficulty: null, subject: null, type: null, "domain.id": null, "domain.title": null, page, limit});
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const selectAndToggle = (key, question) => {
        setSelected(question);
        if(key === "update"){
            setFormData(question);
            toggleUpdate();
        } else {
            toggleDelete();
        }
    }

    const validateForm = () => {
        const newErrors = {};
        if (!formData.type) newErrors.type = 'Type is required';
        if (!formData.code) newErrors.code = 'Code is required';
        if (!formData.subject) newErrors.subject = 'Subject is required';
        if (!formData.questionCount) newErrors.questionCount = 'Question count is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const deleteQuestion = async () => {
        if (!selected) return;

        try {
            await deleteQuestionRequest(selected._id);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                dispatch(fetchFilteredQuestionsAsync(params));
                toggleDelete();
            }, 2000);
        } catch (error) {
            setErrors({ apiError: error.response?.data?.message || 'An error occurred' });
        }
    };

    const updateQuestion = async () => {
        if (!formData) return;
        try {
            delete formData.progress;
        } catch(e) {}
        try {
            await updateQuestionRequest(selected._id, formData);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                dispatch(fetchFilteredQuestionsAsync(params));
                toggleUpdate();
            }, 2000);
        } catch (error) {
            setErrors({ apiError: error.response?.data?.message || 'An error occurred' });
        }
    };

    useEffect(()=>{
        dispatch(fetchFilteredQuestionsAsync(filters));
    }, [filters]);


    

    return (
        <>
            <AdminLayout>
                <>
                    <Row className='mb-0' style={{overflow: 'hidden'}}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="filterCode" className="mr-sm-2">Difficulty</Label>
                                                <Input type="select" id="itemsPerPageSelect" value={filters.difficulty} onChange={(e) => setFilters({...filters, difficulty: e.target.value})}>
                                                    <option></option>
                                                    <option value="FOUNDATION">Foundation</option>
                                                    <option value="INTERMEDIATE">Intermediate</option>
                                                    <option value="ADVANCE">Advance</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="filterSubject" className="mr-sm-2">Subject</Label>
                                                <Input type="select" id="itemsPerPageSelect" value={filters.subject} onChange={(e) => setFilters({...filters, subject: e.target.value})}>
                                                    <option></option>
                                                    <option value="CCP">CCP</option>
                                                    <option value="CCA">CCA</option>
                                                    <option value="NIST">NIST</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="filterSubject" className="mr-sm-2">Type</Label>
                                                <Input type="select" id="itemsPerPageSelect" value={filters.type} onChange={(e) => setFilters({...filters, type: e.target.value})}>
                                                    <option></option>
                                                    <option value="MCQ">MCQs</option>
                                                    <option value="TRUEFALSE">TFQs</option>
                                                    <option value="OPENENDED">OEQs</option>
                                                    <option value="SCENARIO">SBQs</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="itemsPerPageSelect" className="mr-sm-2">Items per page</Label>
                                                <Input type="select" id="itemsPerPageSelect" value={filters.limit} onChange={(e) => setFilters({...filters, limit: e.target.value})}>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                        </Col>
                                        <Col style={{textAlign: 'right', paddingTop: '30px'}}>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div style={{maxHeight: 'calc(100vh - 280px)', overflowY: 'auto'}}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Subject</th>
                                                    <th>Difficulty</th>
                                                    <th>Type</th>
                                                    <th>Attempted</th>
                                                    <th>Flagged</th>
                                                    <th>Like/Dislike</th>
                                                    <th>Model</th>
                                                    <th>Time</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {questions.map((question, index) => (
                                                    <tr key={"question_"+index}>
                                                        <td>{(page-1)*limit + (index + 1)}</td>
                                                        <td>{question.subject}</td>
                                                        <td>{question.difficulty}</td>
                                                        <td>{questionTypes[question.type]}</td>
                                                        <td>{question.userAnswers.length}</td>
                                                        <td>{question.flags.length ? <Badge color="danger">Flags ({question.flags.length})</Badge> : <Badge color="info">Flags (0)</Badge>}</td>
                                                        <td>{question.rating.upVote.length - 1 } / {question.rating.downVote.length - 1}</td>
                                                        <td>{question.generated_by == "" ? "GPT-4" : question.generated_by}</td>
                                                        <td>{question.created_at ? new Date(question.created_at).toDateString(): ''}</td>
                                                        <td>
                                                            <RiEdit2Fill onClick={() => selectAndToggle("update", question)} size={18} style={{cursor: "pointer", color: "green"}} />{' '}
                                                            <MdDelete onClick={() => selectAndToggle("delete", question)} size={18} style={{cursor: "pointer", color: "red"}} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <CustomPagination
                                        currentPage={page}
                                        totalPages={totalPages}
                                        onPageChange={(e)=>setFilters({...filters, page:e})}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    {/* Delete Modal */}
                    <Modal isOpen={modalDelete} toggle={toggleDelete}>
                        <ModalHeader toggle={toggleDelete}>Delete Question</ModalHeader>
                        <ModalBody>
                            <strong>Are you sure you want to delete this question?</strong>
                            
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={deleteQuestion}>Delete</Button>{' '}
                            <Button color="secondary" onClick={toggleDelete}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    {/* Update Modal */}
                    <Modal isOpen={modalUpdate} toggle={toggleUpdate}>
                        <ModalHeader toggle={toggleUpdate}>Update Question</ModalHeader>
                        <ModalBody>
                            <Form>
                                {errors.apiError && <Alert color="danger">{errors.apiError}</Alert>}
                                {success && <Alert color="success">Question updated successfully!</Alert>}
                                <FormGroup>
                                    <Label for="type">Statement</Label>
                                    <Input rows={3} type="textarea" name="statement" id="statement" value={formData.statement} onChange={handleChange}/>
                                    {errors.statement && <Alert color="danger">{errors.statement}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="type">Type</Label>
                                    <Input type="select" name="type" id="type" value={formData.type} onChange={handleChange}>
                                        <option value="">Select Type</option>
                                        <option value="MCQ">MCQs</option>
                                        <option value="TRUEFALSE">TFQs</option>
                                        <option value="OPENENDED">OEQs</option>
                                        <option value="SCENARIO">SBQs</option>
                                    </Input>
                                    {errors.type && <Alert color="danger">{errors.type}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="subject">Subject</Label>
                                    <Input type="select" name="subject" id="subject" value={formData.subject} onChange={handleChange}>
                                        <option value="">Select Subject</option>
                                        <option value="CCP">CCP</option>
                                        <option value="CCA">CCA</option>
                                        <option value="NIST">NIST</option>
                                    </Input>
                                    {errors.subject && <Alert color="danger">{errors.subject}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="difficulty">Difficulty</Label>
                                    <Input type="select" name="difficulty" id="difficulty" value={formData.difficulty} onChange={handleChange}>
                                        <option value="">Select Difficulty</option>
                                        <option value="FOUNDATION">Foundation</option>
                                        <option value="INTERMEDIATE">Intermediate</option>
                                        <option value="ADVANCE">Advance</option>
                                    </Input>
                                    {errors.difficulty && <Alert color="danger">{errors.difficulty}</Alert>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="type">Correct Answer</Label>
                                    <Input rows={3} type="text" name="answer" id="answer" value={formData.answer} onChange={handleChange}/>
                                    {errors.answer && <Alert color="danger">{errors.answer}</Alert>}
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={updateQuestion}>Update</Button>{' '}
                            <Button color="secondary" onClick={toggleUpdate}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </>
            </AdminLayout>
        </>
    );
};

export default Questions;
