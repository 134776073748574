import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchListedTitles } from '../helper/requests';

export const fetchListedTitlesAsync = createAsyncThunk(
  'quizSelection/fetchListedTitles',
  async () => {
    return await fetchListedTitles();
  }
);



const initialState = {
  listedTitles: [],
  status: 'idle',
  error: null,
  selection: null
};

const quizSelectionSlice = createSlice({
    name: 'quizSelection',
    initialState,
    reducers: {
        fetchListedTitlesStart: state => {
            state.status = 'loading';
        },
        fetchListedTitlesSuccess: (state, action) => {
            console.log(action)
            state.status = 'succeeded';
            state.listedTitles = action.payload;
        },
        fetchListedTitlesFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        setQuizSelection: (state, action) => {
            console.log(action)
            state.selection = action.payload;
        },
    },
    extraReducers: builder => {
        builder
        .addCase(fetchListedTitlesAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchListedTitlesAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.listedTitles = action.payload;
        })
        .addCase(fetchListedTitlesAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export const { fetchListedTitlesStart, fetchListedTitlesSuccess, fetchListedTitlesFailure, setQuizSelection } = quizSelectionSlice.actions;

export const selectListedTitles = state => state.quizSelection.listedTitles;
export const selectStatus = state => state.quizSelection.status;
export const selectError = state => state.quizSelection.error;
export const getQuizSelection = state => state.quizSelection.selection;

export default quizSelectionSlice.reducer;
