import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

const FilterRow = ({ filters, handleFilterChange }) => {
  return (
    <Row className='my-4'>
      <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterType" className="mr-sm-2">Type</Label>
          <Input type="select" name="type" id="filterType" value={filters.type} onChange={handleFilterChange}>
            <option value="">Select Type</option>
            <option value="MCQ">MCQ</option>
            <option value="TRUEFALSE">TRUEFALSE</option>
            <option value="OPENENDED">OPENENDED</option>
            <option value="SCENARIO">SCENARIO</option>
          </Input>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterSubject" className="mr-sm-2">Subject</Label>
          <Input type="select" name="subject" id="filterSubject" value={filters.subject} onChange={handleFilterChange}>
            <option value="CCP">CCP</option>
            <option value="CCA">CCA</option>
            <option value="NIST">NIST</option>
          </Input>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterDomain" className="mr-sm-2">Domain</Label>
          <Input type="select" name="domain" id="filterDomain" value={filters.domain} onChange={handleFilterChange}>
            <option value="">Select Domain</option>
            <option value="CMMC Assessment Process">CMMC Assessment Process</option>
            <option value="CMMC Ecosystem">CMMC Ecosystem</option>
            <option value="CMMC Governance and Source Documents">CMMC Governance and Source Documents</option>
            <option value="CMMC Model Construct and Implementation Evaluation">CMMC Model Construct and Implementation Evaluation</option>
            <option value="CMMC-AB Code of Professional Conduct (Ethics)">CMMC-AB Code of Professional Conduct (Ethics)</option>
            <option value="Scoping">Scoping</option>
          </Input>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterDifficulty" className="mr-sm-2">Difficulty</Label>
          <Input type="select" name="difficulty" id="filterDifficulty" value={filters.difficulty} onChange={handleFilterChange}>
            <option value="">Select Difficulty</option>
            <option value="FOUNDATION">Foundation</option>
            <option value="INTERMEDIATE">Intermediate</option>
            <option value="ADVANCE">Advance</option>
          </Input>
        </FormGroup>
      </Col>
      {/* <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterTask" className="mr-sm-2">Task</Label>
          <Input type="text" name="task" id="filterTask" placeholder="Task" value={filters.task} onChange={handleFilterChange} />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterTopic" className="mr-sm-2">Topic</Label>
          <Input type="text" name="topic" id="filterTopic" placeholder="Topic" value={filters.topic} onChange={handleFilterChange} />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="filterStatus" className="mr-sm-2">Status</Label>
          <Input type="text" name="status" id="filterStatus" placeholder="Status" value={filters.status} onChange={handleFilterChange} />
        </FormGroup>
      </Col> */}
    </Row>
  );
};

export default FilterRow;
