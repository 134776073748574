import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatusNew, selectErrorNew, fetchNewQuizByIdAsync, setFlagNew, setUpVoteNew, setDownVoteNew, setAnswerNew, setUpVoteReasoningNew, setDownVoteReasoningNew, fetchNextQuestionAsync, selectQuizNew } from '../redux/quizSliceNew';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Card, CardHeader, CardBody, CardFooter, Row, Col, FormGroup, Input, Label, Navbar, NavbarBrand, NavbarText, Collapse, Nav, NavItem, ListGroup, ListGroupItem, Badge, Alert, Tooltip } from 'reactstrap';
import logo from '../assets/images/cmmc-logo.webp'
import { FaFlag, FaRegClock } from "react-icons/fa";
import Countdown from 'react-countdown';
import { RiFlag2Fill, RiFlag2Line, RiThumbUpFill, RiThumbUpLine, RiThumbDownFill, RiThumbDownLine } from "react-icons/ri";
import { addFlag, downVote, downVoteReasoning, removeFlag, submitQuiz, upVote, upVoteReasoning, updateUserAnswer } from '../helper/requests';
import ConfirmModal from '../components/ConfirmModal';
import TimeCounter from '../components/TimeCounter';
import { selectMetaData } from '../redux/codeSlice';

const QuizScreenNew = () => {
    const metaData = useSelector(selectMetaData)
    const reviewDiv = useRef(null)
    const dispatch = useDispatch();
    const { quizId } = useParams();
    const navigate = useNavigate();
    const quiz = useSelector(selectQuizNew);
    const status = useSelector(selectStatusNew);
    const error = useSelector(selectErrorNew);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [selectedOption, setSelectedOption] = useState('');
    const [isReviewed, setIsReviewed] = useState(false)
    const [marginBottom, setMarginBottom] = useState('0px')

    const [isOpen, setIsOpen] = useState(false);
    const [started, setStarted] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        dispatch(fetchNewQuizByIdAsync(quizId));
    }, [dispatch, quizId]);

  
    useEffect(() => {
        if(metaData && metaData.code && quiz && !started){
            const code = metaData.code
            const subject = metaData.subject
            dispatch(fetchNextQuestionAsync({code, subject}));
            setStarted(true)
        }
    }, [dispatch, metaData, quiz]);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handlePrevious = async() => {
        if(currentQuestionIndex>0){
            setIsReviewed(false)
            let selectedAnswer = quiz.questionIds[currentQuestionIndex-1].userAnswer
            setCurrentQuestionIndex(currentQuestionIndex-1)
            setSelectedOption(selectedAnswer?selectedAnswer:'')
        }
    };

    const handleNext = async() => {
        if(quiz && quiz.questionIds && currentQuestionIndex < quiz.questionIds.length-1){
            setIsReviewed(false)
            let selectedAnswer = quiz.questionIds[currentQuestionIndex+1].userAnswer
            setCurrentQuestionIndex(currentQuestionIndex+1)
            setSelectedOption(selectedAnswer?selectedAnswer:'')
        }
    };

    const goToQuestionIndex = async(index) => {
        setIsReviewed(false)
        let userAnswer = selectedOption
        if(userAnswer){
            let code = quiz.code
            let optionIndex = userAnswer
            if( quiz.questionIds[currentQuestionIndex].type == "MCQ"){
                userAnswer = selectedOption.length?selectedOption:null
                optionIndex = quiz.questionIds[currentQuestionIndex].options.map(option => option.text).indexOf(userAnswer)
            }
            const questionId = quiz.questionIds[currentQuestionIndex]._id
            let data = JSON.parse(JSON.stringify({code, userAnswer: optionIndex, quizId}))
            dispatch(setAnswerNew(JSON.parse(JSON.stringify({index: currentQuestionIndex, value: userAnswer}))))
            let selectedAnswer = quiz.questionIds[index].userAnswer
            setCurrentQuestionIndex(index)
            setSelectedOption(selectedAnswer)
            if(userAnswer != '' && userAnswer != null){
                await updateUserAnswer(questionId, data)
            }
        }else{
            setCurrentQuestionIndex(index)
            let selectedAnswer = quiz.questionIds[index].userAnswer
            setSelectedOption(selectedAnswer?selectedAnswer:'')
        }
    };
    const [feedback, setFeedback] = useState(null)
    const [loading, setLoading] = useState(false)
    const setReviewed = async () => {
        setLoading(true)
        setFeedback(null)
        let userAnswer = selectedOption
        if(userAnswer !== '' || userAnswer !== null){
            let code = quiz.code
            let optionIndex = userAnswer
            if( quiz.questionIds[currentQuestionIndex].type == "MCQ" || quiz.questionIds[currentQuestionIndex].type == "SCENARIO-FOLLOWUPS"){
                userAnswer = selectedOption.length?selectedOption:null
                optionIndex = quiz.questionIds[currentQuestionIndex].options.map(option => option.text).indexOf(userAnswer)
            }
            const questionId = quiz.questionIds[currentQuestionIndex]._id
            let data = JSON.parse(JSON.stringify({code, userAnswer: optionIndex, quizId}))
            dispatch(setAnswerNew(JSON.parse(JSON.stringify({index: currentQuestionIndex, value: userAnswer}))))
            let res = null
            if(userAnswer !== '' && userAnswer !== null){
                res = await updateUserAnswer(questionId, data)
                if(metaData.code && metaData.subject && currentQuestionIndex >= quiz.questionIds.length-1){
                    const code = metaData.code
                    const subject = metaData.subject
                    dispatch(fetchNextQuestionAsync({code, subject}));
                }
            }
            if(quiz.questionIds[currentQuestionIndex].type == "OPENENDED"){
                if(res){
                    setFeedback(res.feedback)
                }
            }
            setIsReviewed(true)
            setLoading(false)
        }
    }

    const handleSubmit = async() => {
        let data = {...quiz.codeQuiz, status: 2}
        let userQuiz = await submitQuiz(data)
        if(userQuiz){
            navigate(`/result/${quiz._id}`)
        }
    };

    const flag = async () => {
        let flag = await addFlag({questionId: quiz.questionIds[currentQuestionIndex]._id, code: quiz.code})
        if(flag){
            dispatch(setFlagNew({index: currentQuestionIndex, value: true}))
        }
    }

    const unFlag = async() => {
        let flag = await removeFlag({questionId: quiz.questionIds[currentQuestionIndex]._id, code: quiz.code})
        if(flag){
            dispatch(setFlagNew({index: currentQuestionIndex, value: false}))
        }
    }

    const addUpVote = async () => {
        let vote = await upVote({questionId: quiz.questionIds[currentQuestionIndex]._id, code: quiz.code})
        if(vote){
            dispatch(setUpVoteNew ({index: currentQuestionIndex, value: true}))
        }
    }

    const addDownVote = async () => {
        let vote = await downVote({questionId: quiz.questionIds[currentQuestionIndex]._id, code: quiz.code})
        if(vote){
            dispatch(setDownVoteNew({index: currentQuestionIndex, value: true}))
        }
    }

    const addUpVoteReasoning = async () => {
        let vote = await upVoteReasoning({questionId: quiz.questionIds[currentQuestionIndex]._id, code: quiz.code})
        if(vote){
            dispatch(setUpVoteReasoningNew({index: currentQuestionIndex, value: true}))
        }
    }

    const addDownVoteReasoning = async () => {
        let vote = await downVoteReasoning({questionId: quiz.questionIds[currentQuestionIndex]._id, code: quiz.code})
        if(vote){
            dispatch(setDownVoteReasoningNew({index: currentQuestionIndex, value: true}))
        }
    }

    const Completionist = () => <span>Time is up!</span>;

    useEffect(()=>{
        if(quiz && quiz.codeQuiz.status == 2){
            navigate(`/result/${quiz._id}`)
        }
        if(currentQuestionIndex == 0 && quiz && quiz.questionIds && quiz.questionIds.length){
            setSelectedOption(quiz.questionIds[0].userAnswer)
        }
    }, [quiz])


    useEffect(()=>{
        if(reviewDiv.current){
            setMarginBottom(reviewDiv.current.offsetHeight+'px')
        }
    }, [isReviewed])

    const [tooltipOpen, setTooltipOpen] = useState({})
    const tooltipToggle = (event) => {
        let key = event.target.id.replace('tooltip-', '')
        let temp = {...tooltipOpen}
        if(temp[key]){
            temp[key] = false
        }else{
            temp[key] = true
        }
        setTooltipOpen(temp);
    }

    const isAnswerCorrect = () => {
        let question = quiz.questionIds[currentQuestionIndex]
        let questionType = question.type
        let userAnswer = question.userAnswer

        if (questionType === 'TRUEFALSE') {
          return userAnswer === question.answer;
        }
      
        if (questionType === 'SCENARIO-FOLLOWUPS' || questionType === 'MCQ') {
          const correctOption = question.options[question.answer];
          return userAnswer === correctOption.text;
        }

        if (questionType === 'OPENENDED') {
            return null;
        }
      
        // Return false for unsupported question types
        return false;
      };

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Navbar
                className="my-2"
                color="dark"
                dark
            >
                <Link to={'#'} className='navbar-brand'>
                    <img src={logo} style={{height: 40, width: 40}}/> { ' ' } 
                    CMMC Training Academy
                </Link>
                <NavbarText>
                    <ConfirmModal click={(event)=>handleSubmit()}></ConfirmModal>
                </NavbarText>
            </Navbar>
            <Container fluid style={{height: '100%'}}>
                {quiz && (
                    <Row style={{height: '100%'}}>
                        {/* <Col md={2} style={{height: 'calc(100vh - 90px)', overflow: "scroll"}} className='pb-5'>
                            <ListGroup>
                                {
                                    quiz.questionIds.map((question, index)=>{
                                        return(
                                            <ListGroupItem style={{cursor: 'pointer'}}  active={currentQuestionIndex==index} onClick={()=>goToQuestionIndex(index)} key={'sidebar_'+index}>
                                                Question {index+1}
                                                {
                                                    // question.userAnswer != null && <MdFileDownloadDone style={{float: 'right', marginTop: '5px'}}/>
                                                }
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                        </Col> */}
                        {
                            quiz.questionIds && quiz.questionIds.length > 0 && 
                            (
                                <Col>
                                    <Row className='pb-5' style={{height: 'calc(100% - 38px)', overflowY: 'auto', marginBottom}}>
                                        <Col md={6} style={{height: '100%'}}>
                                            <Row>
                                                <Col>
                                                    <p><strong>Question: {currentQuestionIndex+1}</strong></p>
                                                    {/* <p><strong>Question: {currentQuestionIndex+1} of {quiz.questionIds.length}</strong></p> */}
                                                </Col>
                                                <Col style={{textAlign: 'right'}}>
                                                    <p>
                                                        <strong>
                                                            <TimeCounter />
                                                        </strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <div className='mb-3' style={{width: '100%', padding: '1rem', border: `1px solid var(--primary)`, borderRadius: '6px', fontSize: '1.2rem', fontWeight:'500'}}>
                                                {
                                                quiz.questionIds[currentQuestionIndex].scenario != null && quiz.questionIds[currentQuestionIndex].scenario.length > 0 && 
                                                <p style={{fontSize: '1rem', fontWeight:'300'}}>{quiz.questionIds[currentQuestionIndex].scenario}</p>
                                                }
                                                {quiz.questionIds[currentQuestionIndex].statement}
                                            </div>
                                            <Alert color='light'>
                                                {
                                                    isReviewed &&
                                                    <>
                                                        <h6 className="alert-heading">
                                                            Why this question?
                                                        </h6>
                                                        <p>
                                                            {quiz.questionIds[currentQuestionIndex].justification}
                                                        </p>
                                                        <hr />
                                                    </>
                                                }
                                                <Row>
                                                    <Col>
                                                        <Badge id='tooltip-difficulty' color="secondary" style={{background: 'var(--primary) !important'}}>
                                                            {quiz.questionIds[currentQuestionIndex].difficulty}
                                                        </Badge>
                                                        <Tooltip
                                                            id="id-difficulty"
                                                            placement='top'
                                                            isOpen={tooltipOpen['difficulty']}
                                                            target={'tooltip-' + 'difficulty'}
                                                            toggle={tooltipToggle}
                                                        >
                                                            Difficulty
                                                        </Tooltip>
                                                    </Col>
                                                    <Col style={{textAlign: 'right'}}>
                                                        <span style={{fontSize: '24px', lineHeight: '10px'}}>
                                                            <span className='mx-4 p-1' id="tooltip-rating">
                                                                {
                                                                    quiz.questionIds[currentQuestionIndex].hasUpVoted ?
                                                                    <RiThumbUpFill style={{cursor: 'pointer'}} color={'var(--primary)'}/>
                                                                    :
                                                                    <RiThumbUpLine onClick={()=>{ addUpVote()}} style={{cursor: 'pointer'}} color={'black'}/>
                                                                }
                                                                {' '}
                                                                {
                                                                    quiz.questionIds[currentQuestionIndex].hasDownVoted ?
                                                                    <RiThumbDownFill style={{cursor: 'pointer'}} color={'var(--primary)'}/>
                                                                    :
                                                                    <RiThumbDownLine onClick={()=>{ addDownVote()}} style={{cursor: 'pointer'}} color={'black'}/>
                                                                }
                                                            </span>
                                                            <Tooltip
                                                                id="id-rating"
                                                                placement='top'
                                                                isOpen={tooltipOpen['rating']}
                                                                target={'tooltip-' + 'rating'}
                                                                toggle={tooltipToggle}
                                                            >
                                                            Rate this question
                                                            </Tooltip>
                                                        {' '}
                                                            <span id="tooltip-flag" className='p-2'>
                                                            {
                                                                quiz.questionIds[currentQuestionIndex].isFlagged ?
                                                                <RiFlag2Fill onClick={()=>{unFlag()}} style={{cursor: 'pointer'}} color={'var(--primary)'}/>
                                                                :
                                                                <RiFlag2Line onClick={()=>{flag()}} style={{cursor: 'pointer'}} color={'black'}/>
                                                            }
                                                            </span>
                                                            <Tooltip
                                                                id="id-flag"
                                                                placement='top'
                                                                isOpen={tooltipOpen['flag']}
                                                                target={'tooltip-' + 'flag'}
                                                                toggle={tooltipToggle}
                                                            >
                                                                Question irrelevant or inappropriate
                                                            </Tooltip>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Alert>
                                        </Col>
                                        { 
                                            quiz.questionIds[currentQuestionIndex].type == "OPENENDED" &&
                                            <Col md={6} style={{height: '100%'}}>
                                                <p>
                                                    <strong>Write your answer below</strong>
                                                </p>
                                                <FormGroup>
                                                    <Input
                                                        id="open-ended-answer"
                                                        name="text"
                                                        type="textarea"
                                                        rows="8"
                                                        value={selectedOption}
                                                        onChange={(e)=>setSelectedOption(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                        { 
                                            (quiz.questionIds[currentQuestionIndex].type == "MCQ" || quiz.questionIds[currentQuestionIndex].type == "SCENARIO-FOLLOWUPS") &&
                                            (
                                                isReviewed ?
                                                <Col md={6} style={{height: '100%'}}>
                                                    <p>
                                                        <strong>Your Result</strong>
                                                    </p>
                                                    {
                                                        quiz.questionIds[currentQuestionIndex].options.map((option, optionIndex) => {
                                                            let isCorrect = false;
                                                            if(option.text == selectedOption){
                                                                isCorrect = true
                                                            }
                                                            
                                                            return (
                                                                <div 
                                                                    className='mb-3' 
                                                                    style={{
                                                                        width: '100%', 
                                                                        padding: '1rem', 
                                                                        border: `1px solid ${optionIndex == quiz.questionIds[currentQuestionIndex].answer?'green':selectedOption===option.text?'red':'silver'}`, 
                                                                        background: `${optionIndex == quiz.questionIds[currentQuestionIndex].answer?'lightgreen':selectedOption===option.text?'lightcoral':'white'}`, 
                                                                        borderRadius: '6px'
                                                                    }} 
                                                                    key={optionIndex} 
                                                                >
                                                                    {option.text}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                :
                                                <Col md={6} style={{height: '100%'}}>
                                                    <p>
                                                        <strong>Select Option</strong>
                                                    </p>
                                                    {
                                                        quiz.questionIds[currentQuestionIndex].options.map((option, optionIndex) => {
                                                            return (
                                                                <div 
                                                                    className='mb-3' 
                                                                    style={{cursor: 'pointer', width: '100%', padding: '1rem', border: `1px dashed ${selectedOption === option.text?'#01395e':'silver'}`, borderRadius: '6px'}} key={optionIndex} 
                                                                    onClick={()=>setSelectedOption(option.text)}
                                                                >
                                                                    <FormGroup check >
                                                                        <Label check style={{cursor: 'pointer', color: selectedOption === option.text ?'#01395e':'inherit'}}>
                                                                                <Input
                                                                                    name={"radio"+currentQuestionIndex}
                                                                                    type="radio"
                                                                                    value={option.text}
                                                                                    checked={selectedOption === option.text}
                                                                                />
                                                                                {' '}
                                                                                {option.text}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                
                                            )
                                        }
                                        {
                                            quiz.questionIds[currentQuestionIndex].type == "TRUEFALSE" &&
                                            (
                                                isReviewed ? 
                                                <Col md={6} style={{height: '100%'}}>
                                                    <p>
                                                        <strong>Your Result</strong>
                                                    </p>
                                                    <div 
                                                        className='mb-3' 
                                                        style={{
                                                            width: '100%', 
                                                            padding: '1rem', 
                                                            border: `1px solid ${1 == quiz.questionIds[currentQuestionIndex].answer?'green':selectedOption==1?'red':'silver'}`, 
                                                            background: `${1 == quiz.questionIds[currentQuestionIndex].answer?'lightgreen':selectedOption==1?'lightcoral':'white'}`,
                                                            borderRadius: '6px'
                                                        }} 
                                                    >
                                                        True
                                                    </div>

                                                    <div 
                                                        className='mb-3' 
                                                        style={{
                                                            width: '100%', 
                                                            padding: '1rem', 
                                                            border: `1px solid ${0 == quiz.questionIds[currentQuestionIndex].answer?'green':selectedOption==0?'red':'silver'}`, 
                                                            background: `${0 == quiz.questionIds[currentQuestionIndex].answer?'lightgreen':selectedOption==0?'lightcoral':'white'}`,
                                                            borderRadius: '6px'
                                                        }} 
                                                    >
                                                        False
                                                    </div>
                                                </Col>
                                                :
                                                <Col md={6} style={{height: '100%'}}>
                                                    <p>
                                                        <strong>Select Option</strong>
                                                    </p>
                                                    <div 
                                                        className='mb-3' 
                                                        style={{cursor: 'pointer', width: '100%', padding: '1rem', border: `1px dashed ${selectedOption === 1?'#01395e':'silver'}`, borderRadius: '6px'}} 
                                                        onClick={()=>setSelectedOption(1)}
                                                    >
                                                        <FormGroup check >
                                                            <Label check style={{cursor: 'pointer', color: selectedOption === 1 ?'#01395e':'inherit'}}>
                                                                    <Input
                                                                        name={"radio"+currentQuestionIndex}
                                                                        type="radio"
                                                                        value={1}
                                                                        checked={selectedOption === 1}
                                                                    />
                                                                    {' '}
                                                                    True
                                                            </Label>
                                                        </FormGroup>
                                                    </div>

                                                    <div 
                                                        className='mb-3' 
                                                        style={{cursor: 'pointer', width: '100%', padding: '1rem', border: `1px dashed ${selectedOption === 0?'#01395e':'silver'}`, borderRadius: '6px'}} 
                                                        onClick={()=>setSelectedOption(0)}
                                                    >
                                                        <FormGroup check >
                                                            <Label check style={{cursor: 'pointer', color: selectedOption === 0 ?'#01395e':'inherit'}}>
                                                                    <Input
                                                                        name={"radio"+currentQuestionIndex}
                                                                        type="radio"
                                                                        value={0}
                                                                        checked={selectedOption === 0}
                                                                    />
                                                                    {' '}
                                                                    False
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <div ref={reviewDiv} className='p-2' style={{position: 'fixed', bottom: '0px', background: 'white', borderTop: '1px solid silver', width: '100%', left: '0px', zIndex: 1000}}>
                                    {
                                        isReviewed ?
                                        <Alert color={isAnswerCorrect() === null ? 'info' : isAnswerCorrect() ? 'success':'danger'}>
                                            <Row>
                                                <Col className='p-5'>
                                                    {
                                                        quiz.questionIds[currentQuestionIndex].type == "OPENENDED" ?
                                                            <h3 color='success'>Feedback</h3>
                                                        :(
                                                            quiz.questionIds[currentQuestionIndex].type == "TRUEFALSE" ?
                                                                <h3 color='success'>Feedback</h3>
                                                            :
                                                            (quiz.questionIds[currentQuestionIndex].type == "MCQ" || quiz.questionIds[currentQuestionIndex].type == "SCENARIO-FOLLOWUPS") &&
                                                            (
                                                                (quiz.questionIds[currentQuestionIndex].options[quiz.questionIds[currentQuestionIndex].answer].text == quiz.questionIds[currentQuestionIndex].userAnswer ) ?
                                                                <h3 color='success'>Well done!</h3>
                                                                : 
                                                                <h3 color='danger'>Let's figure out the right answer!</h3>
                                                            )
                                                        )
                                                        
                                                    }
                                                    {
                                                        // quiz.questionIds[currentQuestionIndex].type == "TRUEFALSE" &&
                                                        // quiz.questionIds[currentQuestionIndex].reasoning
                                                    }

                                                    {
                                                        quiz.questionIds[currentQuestionIndex].type == "OPENENDED" && feedback != null &&
                                                        <p>{feedback}</p>
                                                    }
                                                    
                                                    {
                                                        (quiz.questionIds[currentQuestionIndex].type == "MCQ" || quiz.questionIds[currentQuestionIndex].type == "SCENARIO-FOLLOWUPS") &&
                                                        (
                                                            (quiz.questionIds[currentQuestionIndex].options[quiz.questionIds[currentQuestionIndex].answer].text == quiz.questionIds[currentQuestionIndex].userAnswer ) ?
                                                            <p>{quiz.questionIds[currentQuestionIndex].options[quiz.questionIds[currentQuestionIndex].answer].analysis}</p>
                                                            : 
                                                            <p>{quiz.questionIds[currentQuestionIndex].options.filter(option=>option.text == quiz.questionIds[currentQuestionIndex].userAnswer)[0].analysis}</p>
                                                        )

                                                    }
                                                    {
                                                        quiz.questionIds[currentQuestionIndex].reasoning && quiz.questionIds[currentQuestionIndex].reasoning.length > 0 &&
                                                        <div>
                                                            <strong>Reasoning:</strong>
                                                            <p>{quiz.questionIds[currentQuestionIndex].reasoning}</p>
                                                        </div>
                                                    }

                                                    {
                                                        quiz.questionIds[currentQuestionIndex].reference && quiz.questionIds[currentQuestionIndex].reference.length > 0 &&
                                                        <div>
                                                            <strong>Reference:</strong>
                                                            <p>{quiz.questionIds[currentQuestionIndex].reference}</p>
                                                        </div>
                                                    }
                                                    {
                                                        quiz.questionIds[currentQuestionIndex].keypoints && quiz.questionIds[currentQuestionIndex].keypoints.length > 0 &&
                                                        <div>
                                                            <strong>Key points:</strong>
                                                            {
                                                                quiz.questionIds[currentQuestionIndex].keypoints.map(keypoint=>{
                                                                    return <Badge color='info'>{keypoint}</Badge>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col>
                                                    <span style={{fontSize: '24px', lineHeight: '10px'}} className='p-2' id='tooltip-feedback'>
                                                        <span className=''>
                                                            {
                                                                quiz.questionIds[currentQuestionIndex].hasUpVotedReasoning ?
                                                                <RiThumbUpFill style={{cursor: 'pointer'}} color={'var(--primary)'}/>
                                                                :
                                                                <RiThumbUpLine onClick={()=>{ addUpVoteReasoning()}} style={{cursor: 'pointer'}} color={'black'}/>
                                                            }
                                                            {' '}
                                                            {
                                                                quiz.questionIds[currentQuestionIndex].hasDownVotedReasoning ?
                                                                <RiThumbDownFill style={{cursor: 'pointer'}} color={'var(--primary)'}/>
                                                                :
                                                                <RiThumbDownLine onClick={()=>{ addDownVoteReasoning()}} style={{cursor: 'pointer'}} color={'black'}/>
                                                            }
                                                        </span>
                                                    </span>
                                                    <Tooltip
                                                        id="id-feedback"
                                                        placement='top'
                                                        isOpen={tooltipOpen['feedback']}
                                                        target={'tooltip-' + 'feedback'}
                                                        toggle={tooltipToggle}
                                                    >
                                                        Rate this feedback
                                                    </Tooltip>
                                                </Col>
                                                <Col style={{textAlign: "right"}}>
                                                    {
                                                        // currentQuestionIndex>0 && <Button className='theme-button' color="secondary" onClick={handlePrevious}>Prev</Button>
                                                    }
                                                    {
                                                        (currentQuestionIndex<quiz.questionIds.length-1) ? 
                                                        <Button className='theme-button' style={{marginLeft: '15px'}} color="primary" onClick={handleNext}>Next</Button>
                                                        :
                                                        <ConfirmModal click={(event)=>handleSubmit()}></ConfirmModal>
                                                    }
                                                </Col>
                                            </Row>
                                        </Alert>
                                        :
                                        <Row>
                                            <Col>
                                                
                                            </Col>
                                            <Col style={{textAlign: "right"}}>
                                                <Button className='theme-button' color="primary" onClick={()=>setReviewed()} disabled={(selectedOption === null || selectedOption === '' || loading)}>{loading?'Please wait ...':'Review Answer'}</Button>
                                            </Col>
                                        </Row>
                                    }
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                )}
            </Container>
        </>
    );
};

export default QuizScreenNew;
