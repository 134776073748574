import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuiz, selectStatus, selectError, fetchQuizByIdAsync, setFlag, setUpVote, setDownVote, setAnswer } from '../redux/quizSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Card, CardHeader, CardBody, CardFooter, Row, Col, FormGroup, Input, Label, Navbar, NavbarBrand, NavbarText, Collapse, Nav, NavItem, ListGroup, ListGroupItem, Badge, Alert, Table, ButtonGroup } from 'reactstrap';
import logo from '../assets/images/cmmc-logo.webp'
import { FaFlag, FaRegClock } from "react-icons/fa";
import Countdown from 'react-countdown';
import { RiFlag2Fill, RiFlag2Line, RiThumbUpFill, RiThumbUpLine, RiThumbDownFill, RiThumbDownLine } from "react-icons/ri";

import { MdFileDownloadDone } from "react-icons/md";
import { addFlag, downVote, removeFlag, submitQuiz, upVote, updateUserAnswer } from '../helper/requests';
import ConfirmModal from '../components/ConfirmModal';
import { type } from '@testing-library/user-event/dist/type';
import { fetchResultAsync, selectResult } from '../redux/dashboardSlice';
import { selectMetaData } from '../redux/codeSlice';
import ResultGraphs from '../components/result/ResultGraphs';
import Result from '../components/major/Result';
import Performance from '../components/major/Performance';
import ReviewAnswers from '../components/major/ReviewAnswers';

const QuizResult = () => {
    const dispatch = useDispatch();
    const { quizId } = useParams();
    const navigate = useNavigate();
    const quiz = useSelector(selectQuiz);
    const status = useSelector(selectStatus);
    const error = useSelector(selectError);

    const [filter, setFilter] = useState("result")

    

    return (
        <>
            <Navbar
                className="my-2"
                color="dark"
                dark
            >
                <Link to={'/'} className='navbar-brand'>
                    <img src={logo} style={{height: 40, width: 40}}/> { ' ' } 
                    CMMC Training Academy
                </Link>
            </Navbar>
            <Container fluid style={{height: '100%'}}>
                <Row className='mb-4'>
                    <Col>
                        <ButtonGroup className="my-2">
                            {
                                [
                                    {key: "result", value: "Quiz Result"}, 
                                    {key:"answers", value:"Review Answers"}, 
                                    {key: "performance", value:"Overall Performance"}
                                ].map(item=>{
                                    return (
                                        <Button color='dark' outline active={item.key === filter} onClick={()=>setFilter(item.key)} key={'filter-'+item.key}>{item.value}</Button>
                                    )
                                })
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col>
                        {
                            filter === 'result' && 
                            <Result />
                        }
                        {
                            filter === 'performance' && 
                            <Performance />
                        }
                        {
                            filter === 'answers' && 
                            <ReviewAnswers />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default QuizResult;
