import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, NavbarBrand, NavbarText, Button, Card, CardHeader, CardBody} from 'reactstrap';
import logo from '../../assets/images/cmmc-logo.webp'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import { adminCodeStatisticsAsync, selectAnswerStats, selectQuestionStats, selectStatistics } from '../../redux/codeSlice';
import FilterRow from '../../components/FilterRow';

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const statistics = useSelector(selectStatistics)
    const questions = useSelector(selectQuestionStats)
    const answers = useSelector(selectAnswerStats)

    const [filters, setFilters] = useState({
        type: '',
        subject: 'CCP',
        domain: '',
        difficulty: '',
        // task: '',
        // topic: '',
        // status: '',
      });
    
      const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
        // Additional logic if needed, e.g., reset pagination
      };

    // const [params, setParams] = useState({...filters})

    const questionTypes = {
        MCQ: "MCQs",
        TRUEFALSE: "TFQs",
        OPENENDED: "OEQs",
        SCENARIO: "SBQs"
    }

    const questionDifficulty = {
        FOUNDATION: "Foundation",
        INTERMEDIATE: "Intermediate",
        ADVANCE: "Advance"
    }



    useEffect(()=>{
        dispatch(adminCodeStatisticsAsync(filters))
    }, [filters])

    return (
        <>
            <AdminLayout>
                <Card>
                    <CardHeader>
                        <FilterRow filters={filters} handleFilterChange={handleFilterChange} />
                    </CardHeader>
                    <CardBody>
                        <div style={{maxHeight: "calc(100vh - 270px)", overflow: "auto"}}>
                        {
                            statistics && 
                            <>
                                <h5>Access Codes</h5>
                                <p><strong>General</strong></p>
                                <Row className='mb-4'>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                Total Codes
                                            </CardHeader>
                                            <CardBody>
                                                {statistics.totalCodes}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                Active Codes
                                            </CardHeader>
                                            <CardBody>
                                                {statistics.codesWithProgressCount}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                Codes Expiring in 7 days
                                            </CardHeader>
                                            <CardBody>
                                                {statistics.codesExpiringSoon}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                
                                <p><strong>Access Level</strong></p>
                                <Row className='mb-4'>
                                    {
                                        [...statistics.typeWiseCount].sort((a,b)=>a._id.length-b._id.length).map((type, index) => {
                                            return (
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {type._id.split('_').map(word=> word[0].toUpperCase()+word.slice(1,word.length)).join(' ')}
                                                        </CardHeader>
                                                        <CardBody>
                                                            {type.count}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                        
                                    }
                                </Row>

                                <hr/>

                                <h5>Questions</h5>
                                <p><strong>General</strong></p>
                                <Row className='mb-4'>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                Total Questions
                                            </CardHeader>
                                            <CardBody>
                                                {questions.totalQuestions}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                Questions with flags
                                            </CardHeader>
                                            <CardBody>
                                                {questions.flaggedQuestionsCount}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                Questions attempted
                                            </CardHeader>
                                            <CardBody>
                                                {questions.attemptedQuestionsCount}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                
                                <p><strong>Types</strong></p>
                                <Row>
                                    {
                                        [...questions.typeWiseQuestions].map((type, index) => {
                                            return (
                                                <Col md={4} className='mb-4' style={{display: type._id=="SCENARIO-FOLLOWUPS" ? 'none' :'initial'}}>
                                                    <Card>
                                                        <CardHeader>
                                                            {questionTypes[type._id]}
                                                        </CardHeader>
                                                        <CardBody>
                                                            {type.count}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                        
                                    }
                                </Row>
                                <p><strong>Domains</strong></p>
                                <Row>
                                    {
                                        [...questions.domainTitleWiseQuestions].map((type, index) => {
                                            return (
                                                <Col md={4} className='mb-4'>
                                                    <Card>
                                                        <CardHeader>
                                                            {type._id}
                                                        </CardHeader>
                                                        <CardBody>
                                                            {type.count}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                        
                                    }
                                </Row>
                                <p><strong>Difficulty</strong></p>
                                <Row>
                                    {
                                        [...questions.difficultyWiseQuestions].map((type, index) => {
                                            return (
                                                <Col md={4} className='mb-4'>
                                                    <Card>
                                                        <CardHeader>
                                                            {questionDifficulty[type._id]}
                                                        </CardHeader>
                                                        <CardBody>
                                                            {type.count}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                        
                                    }
                                </Row>

                                <hr/>

                                <h5>Users Activity</h5>
                                <p><strong>General</strong></p>
                                <Row>
                                    <Col md={4} className='mb-4'>
                                        <Card>
                                            <CardHeader>
                                                Avg Questions Per User
                                            </CardHeader>
                                            <CardBody>
                                                {parseInt(answers.avgQuestionsPerCode)}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4} className='mb-4'>
                                        <Card>
                                            <CardHeader>
                                                Avg Questions Per Quiz
                                            </CardHeader>
                                            <CardBody>
                                                {parseInt(answers.avgQuestionsPerQuiz)}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4} className='mb-4'>
                                        <Card>
                                            <CardHeader>
                                                Avg Accuracy
                                            </CardHeader>
                                            <CardBody>
                                                {parseInt(answers.avgAccuracyPerCode)}%
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4} className='mb-4'>
                                        <Card>
                                            <CardHeader>
                                                Avg Questions Per Day
                                            </CardHeader>
                                            <CardBody>
                                                {parseInt(answers.avgQuestionsPerCodePerDay)}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4} className='mb-4'>
                                        <Card>
                                            <CardHeader>
                                                Avg Active Users Per Day
                                            </CardHeader>
                                            <CardBody>
                                                {parseInt(answers.avgUsersPerDay)}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        }
                        </div>
                    </CardBody>
                </Card>
            </AdminLayout>
        </>
    );
};

export default Home;
