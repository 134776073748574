// src/components/PrivateRoute.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMetaData } from '../redux/codeSlice';

const PrivateRoute = (props) => {
    const navigate = useNavigate()
    const metaData = useSelector(selectMetaData);

    useEffect(()=>{
        if(!metaData){
            navigate('/login')
        }
    }, [metaData])

    return props.children;
};

export default PrivateRoute;
