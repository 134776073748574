import React from 'react';
import { Container, Row, Col, Navbar, NavbarBrand, NavbarText, Button, Nav, NavItem} from 'reactstrap';
import logo from '../../assets/images/cmmc-logo.webp'
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const AdminLayout = ({children}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const logout = () => {
        localStorage.clear();
        navigate('/admin/login');
    }

    return (
        <>
            <Navbar
                className="my-2"
                color="dark"
                dark
            >
                <Link to={'/admin/home'} className='navbar-brand'>
                    <img src={logo} style={{height: 40, width: 40}}/> { ' ' } CMMC Training Academy
                </Link>
                <NavbarText>
                    <Button className='theme-button' color="success" onClick={()=> logout()}>Logout</Button>
                </NavbarText>
            </Navbar>
            <Container fluid style={{height: '100%'}}>
                <Row>
                    <Col md={2} style={{minHeight: 'calc(100vh - 70px)', borderRight: '1px solid silver'}}>
                        <Nav justified pills vertical card>
                            <NavItem>
                                <NavLink active className="nav-link" to="/admin/home" activeClassName="active">
                                    Statistics
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to="/admin/access-codes" activeClassName="active">
                                    Access Codes
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to="/admin/questions" activeClassName="active">
                                    Questions
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col>
                        {children}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AdminLayout;
