import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ConfirmModal({click}) {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <Button className='theme-button' color="success" onClick={toggle}>Finish Exam</Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure you want to finish the quiz?
                    </ModalBody>
                <ModalFooter>
                    <Button className='theme-button' color="success" onClick={()=>click()}>
                        Confirm
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ConfirmModal;