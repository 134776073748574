import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    const maxPagesToShow = 5; // Adjust this number to change the maximum number of page buttons to show

    const getPageNumbers = () => {
        const pages = [];
        const halfMaxPages = Math.floor(maxPagesToShow / 2);

        if (totalPages <= maxPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else if (currentPage <= halfMaxPages) {
            for (let i = 1; i <= maxPagesToShow; i++) {
                pages.push(i);
            }
            pages.push('...', totalPages);
        } else if (currentPage > totalPages - halfMaxPages) {
            pages.push(1, '...');
            for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1, '...');
            for (let i = currentPage - halfMaxPages; i <= currentPage + halfMaxPages; i++) {
                pages.push(i);
            }
            pages.push('...', totalPages);
        }

        return pages;
    };

    const handlePageClick = (page) => {
        if (page !== '...') {
            onPageChange(page);
        }
    };

    return (
        <Pagination>
            <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink onClick={() => onPageChange(currentPage - 1)} previous />
            </PaginationItem>
            {getPageNumbers().map((page, index) => (
                <PaginationItem active={page === currentPage} key={index} disabled={page === '...'}>
                    <PaginationLink onClick={() => handlePageClick(page)}>
                        {page}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink onClick={() => onPageChange(currentPage + 1)} next />
            </PaginationItem>
        </Pagination>
    );
};

export default CustomPagination;
