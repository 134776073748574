import React from 'react';
import ReactECharts from 'echarts-for-react';

const RadarChart = ({ domains, domainData }) => {
    const option = {
        title: {
            text: 'Domain Accuracy',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function(params){
                console.log("Main", params)
                let tooltipContent = "<strong style='color:"+params.color+"'>"+params.data.name + "</strong><br/>";
                params.data.value.forEach(function (item, index) {
                    tooltipContent += '<span style="display:inline-block;width:420px; overflow: wrap">' + 'Domain ' + (index + 1) + ': ' + domains[index] + '</span> ' + item + '%<br>';
                });
                
                return tooltipContent;
            }
        },
        // radar: {
        //     indicator: domains.map(domain => ({ name: domain, max: 100 })),
        // },
        radar: {
            center: ['50%', '50%'], 
            radius: '70%',
            indicator: domains.map((domain, index) => ({ name: `Domain ${index + 1}` , max: 100 })),
        },
        legend: {
            data: ['Correct', 'Incorrect'],
            top: 'bottom',
            left: "left"
        },
        height: 230,
        grid: {
            bottom: 100
        },
        colors: ["#FF6E76", "#90cd75"],
        series: [
            {
                name: 'Domain Accuracy',
                type: 'radar',
                data: [
                    {
                        name: 'Incorrect',
                        lineStyle: {
                            color: '#FF6E76'
                        },
                        itemStyle: {
                            color: '#FF6E76'
                        },
                        value: domains.map(domain => {
                            const domainStats = domainData[domain];
                            if (domainStats) {
                                const total = domainStats.total;
                                const incorrect = domainStats.incorrect;
                                return ((incorrect / total) * 100).toFixed(1);
                            }
                            return 0;
                        }),
                    },
                    {
                        name: 'Correct',
                        lineStyle: {
                            color: '#90cd75'
                        },
                        itemStyle: {
                            color: '#90cd75'
                        },
                        value: domains.map(domain => {
                            const domainStats = domainData[domain];
                            if (domainStats) {
                                const total = domainStats.total;
                                const correct = domainStats.correct;
                                return ((correct / total) * 100).toFixed(1);
                            }
                            return 0;
                        }),
                    }
                ],
            }
        ],
    };

    return (
        <div className='border rounded border-2'>
            <ReactECharts option={option} style={{ height: '300px' }} />
        </div>
    )
};

export default RadarChart;
