import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNewQuizById, fetchNextQuestion } from '../helper/requests';

export const fetchNewQuizByIdAsync = createAsyncThunk(
    'quizNew/fetchNewQuizById',
    async (quizId) => {
        return await fetchNewQuizById(quizId);
    }
);

export const fetchNextQuestionAsync = createAsyncThunk(
    'quizNew/fetchNextQuestion',
    async (data) => {
        return await fetchNextQuestion(data);
    }
);

const initialState = {
    quiz: null,
    status: 'idle',
    error: null
};

const quizSliceNew = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        fetchNewQuizByIdStart: state => {
            state.status = 'loading';
        },
        fetchNewQuizByIdSuccess: (state, action) => {
            state.status = 'succeeded';
            state.quiz = action.payload;
        },
        fetchNewQuizByIdFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        fetchNextQuestionStart: state => {
            state.status = 'loading';
        },
        fetchNextQuestionSuccess: (state, action) => {
            state.status = 'succeeded';
            state.quiz = action.payload;
        },
        fetchNextQuestionFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        setFlagNew: (state, action) => {
            state.quiz.questionIds[action.payload.index].isFlagged = action.payload.value
        },
        setUpVoteNew: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVoted = true
            state.quiz.questionIds[action.payload.index].hasDownVoted = false
        },
        setDownVoteNew: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVoted = false
            state.quiz.questionIds[action.payload.index].hasDownVoted = true
        },
        setUpVoteReasoningNew: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVotedReasoning = true
            state.quiz.questionIds[action.payload.index].hasDownVotedReasoning = false
        },
        setDownVoteReasoningNew: (state, action) => {
            state.quiz.questionIds[action.payload.index].hasUpVotedReasoning = false
            state.quiz.questionIds[action.payload.index].hasDownVotedReasoning = true
        },
        setAnswerNew: (state, action) => {
            console.log("Answer Index New", action)
            state.quiz.questionIds[action.payload.index].userAnswer = action.payload.value
        }
    },
    extraReducers: builder => {
        builder
        .addCase(fetchNewQuizByIdAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchNewQuizByIdAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.quiz = action.payload;
        })
        .addCase(fetchNewQuizByIdAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchNextQuestionAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchNextQuestionAsync.fulfilled, (state, action) => {
            console.log("Questions Fetch", action)
            state.status = 'succeeded';
            if(action.payload && action.payload.questions){
                state.quiz.questionIds.push(...action.payload.questions);
            }
        })
        .addCase(fetchNextQuestionAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export const { 
    fetchNewQuizByIdStart, 
    fetchNewQuizByIdSuccess, 
    fetchNewQuizByIdFailure, 
    fetchNextQuestionStart,
    fetchNextQuestionSuccess,
    fetchNextQuestionFailure,
    setFlagNew, 
    setUpVoteNew, 
    setDownVoteNew,
    setUpVoteReasoningNew,
    setDownVoteReasoningNew, 
    setAnswerNew 
} = quizSliceNew.actions;

export const selectQuizNew = (state) => state.quizNew.quiz;
export const selectStatusNew = (state) => state.quizNew.status;
export const selectErrorNew = (state) => state.quizNew.error;

export default quizSliceNew.reducer;
