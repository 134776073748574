import React from 'react';
import ReactEcharts from 'echarts-for-react';

function DailyProgress({ data }) {
    // Extract data for last 30 days
    const last30DaysData = Object.keys(data).slice(-30).map(date => ({
        date,
        total: data[date].total,
        correct: data[date].correct,
        recommended: data[date].recommended,
    }));

    // Fill in missing days with zero values
    const currentDate = new Date();
    const endDate = new Date(currentDate.getTime() - 29 * 24 * 60 * 60 * 1000);
    const dates = [];
    for (let i = endDate; i <= currentDate; i.setDate(i.getDate() + 1)) {
        dates.push(i.toISOString().slice(0, 10));
    }
    const filledData = dates.map(date => {
        const existingData = last30DaysData.find(item => item.date === date);
        if (existingData) {
            return existingData;
        } else {
            return {
                date,
                total: 0,
                correct: 0,
                recommended: 20, // Assuming 20 is the recommended value for missing days
            };
        }
    });

    // Prepare x-axis labels (dates)
    const xAxisData = filledData.map(item => item.date);

    // Prepare series data for total, correct, and recommended values
    const totalData = filledData.map(item => item.total);
    const correctData = filledData.map(item => item.correct);
    const incorrectData = filledData.map(item => item.total-item.correct);
    const recommendedData = filledData.map(item => item.recommended);

    // Define options for the echarts graph
    const option = {
        title: {
            text: 'Progress',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        toolbox: {
            show: true,
            feature: {
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: ['Total', 'Incorrect', 'Correct'],
            top: 'bottom',
        },
        grid: {
            bottom: 100
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
        },
        yAxis: [
            {
                type: 'value'
            }
        ],
        dataZoom: [
            {
                type: 'slider',
                start: 75,
                end: 100,
                bottom: 30
            },
            {
                type: 'inside',
                start: 0,
                end: 50,
                bottom: 30
            },
        ],
        series: [
            {
                name: 'Total',
                type: 'bar',
                // type: 'line',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                // stack: 'question',
                smooth: true,
                data: totalData,
            },
            {
                name: 'Incorrect',
                type: 'bar',
                // type: 'line',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                // stack: 'question',
                smooth: true,
                color: "#FF6E76",
                data: incorrectData,
            },
            {
                name: 'Correct',
                type: 'bar',
                // type: 'line',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                // stack: 'question',
                smooth: true,
                color: "#90cd75",
                data: correctData,
            },
            // {
            //     name: 'Recommended',
            //     type: 'line',
            //     data: recommendedData,
            // },
        ],
    };

    return(
        <div className='border rounded border-2'>
            <ReactEcharts option={option} style={{ height: '300px'}} />
        </div>
    )
}

export default DailyProgress;
