import React from 'react';
import { Container, Row, Col, Navbar, NavbarBrand, NavbarText, Button} from 'reactstrap';
import logo from '../assets/images/cmmc-logo.webp'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Performance from '../components/major/Performance';

const Dashboard = () => {
    console.log("V1.1")
    const navigate = useNavigate();
    const dispatch = useDispatch()
    

    return (
        <>
            <Navbar
                className="my-2"
                color="dark"
                dark
            >
                
                <Link to={'/'} className='navbar-brand'>
                    <img src={logo} style={{height: 40, width: 40}}/> { ' ' } CMMC Training Academy
                </Link>
                <NavbarText>
                    <Button className='theme-button' color="success" onClick={()=> navigate('/quiz-customize')}>Start New Quiz</Button>
                </NavbarText>
            </Navbar>
            <Container fluid style={{height: '100%'}}>
                <Performance />
            </Container>
        </>
    );
};

export default Dashboard;
