import axios from 'axios';
import { createBrowserHistory } from 'history';

// Create a history object
const history = createBrowserHistory();


axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      // Redirect to login page
      console.log("redirecting")
      let access = localStorage.getItem("adminAccess")
      if(access){
        history.push('/admin/login');
        localStorage.removeItem("adminAccess")
      }else{
        history.push('/login');
        localStorage.removeItem("access")
      }
      window.location.reload();

    }
    return Promise.reject(error);
  }
);


var _baseUrl = "";
if(window.location.hostname === "localhost"){
    _baseUrl = "http://localhost:4200";
}else{
    _baseUrl = window.location.protocol+"//"+window.location.host;
}
const baseUrl = _baseUrl;
const url = baseUrl + "/api";

// const url = 'https://magic.cybercertify.me/api'
// const url = 'http://localhost:4200/api'

const getToken = () => {
  let payload = localStorage.getItem("access")
  if(payload){
    payload = JSON.parse(payload);
  }
  return payload.token
}


export const decrypt = (input) => {
  return input;
}


export const fetchListedTitles = async () => {
  try {
    let response = await axios.get( `${url}/listedTitles` );
    response.data = decrypt(response.data);
    return response.data.listedTitles;
  } catch (error) {
    throw new Error('Error fetching listed titles');
  }
};

export const fetchQuizById = async (quizId) => {
    try {
      const response = await axios.get(`${url}/quizzes/${quizId}`);
      response.data = decrypt(response.data);
      return response.data.quiz;
    } catch (error) {
      throw new Error('Error fetching quiz');
    }
}

export const fetchNewQuizById = async (quizId) => {
  try {
    const response = await axios.get(`${url}/quizzes/new-quiz/${quizId}`);
    response.data = decrypt(response.data);
    return response.data.quiz;
  } catch (error) {
    throw new Error('Error fetching quiz');
  }
}

export const fetchNextQuestion = async (data) => {
    try {
      const response = await axios.post(`${url}/questions/next-question`, {...data});
      response.data = decrypt(response.data);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching quiz');
    }
}


export const validateCode = async (code) => {
  try {
    const response = await axios.post(`${url}/accessCodes/inspect`, {code});
    response.data = decrypt(response.data);
    return response.data;
  } catch (error) {
    return null
  }
}


export const createUserRequest = async (user) => {
  try {
    const response = await axios.post(`${url}/users`, user);
    response.data = decrypt(response.data);
    return response.data.user;
  } catch (error) {
    return null
  }
}

export const createUserQuizRequest = async (criteria) => {
  try {
    const response = await axios.post(`${url}/userQuizzes`, criteria);
    response.data = decrypt(response.data);
    return response.data.userQuiz;
  } catch (error) {
    return null
  }
}

export const createCodeQuizRequest = async (criteria) => {
  try {
    const response = await axios.post(`${url}/codeQuizzes`, criteria);
    response.data = decrypt(response.data);
    return response.data.codeQuiz;
  } catch (error) {
    return null
  }
}

export const addFlag = async (data) => {
  try {
    const response = await axios.post(`${url}/questions/addFlag`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const removeFlag = async (data) => {
  try {
    const response = await axios.post(`${url}/questions/removeFlag`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const upVote = async (data) => {
  try {
    const response = await axios.post(`${url}/questions/upVote`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const downVote = async (data) => {
  try {
    const response = await axios.post(`${url}/questions/downVote`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const upVoteReasoning = async (data) => {
  try {
    const response = await axios.post(`${url}/questions/upVoteReasoning`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const downVoteReasoning = async (data) => {
  try {
    const response = await axios.post(`${url}/questions/downVoteReasoning`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const updateUserAnswer = async (questionId, data) => {
  try {
    const response = await axios.put(`${url}/questions/${questionId}/user-answers`, data);
    response.data = decrypt(response.data);
    return response.data;
  } catch (error) {
    return null
  }
}

export const submitQuiz = async (data) => {
  try {
    const response = await axios.put(`${url}/codeQuizzes/${data._id}`, data);
    response.data = decrypt(response.data);
    return true;
  } catch (error) {
    return false
  }
}

export const fetchDashboard = async (data) => {
  try {
    const response = await axios.post(`${url}/answers/dashboard`, data);
    response.data = decrypt(response.data);
    return response.data;
  } catch (error) {
    return false
  }
}

export const fetchResult = async (data) => {
  try {
    const response = await axios.post(`${url}/answers/result`, data);
    response.data = decrypt(response.data);
    return response.data;
  } catch (error) {
    return false
  }
}


export const authenticate = async (data) => {
  try {
    const response = await axios.post(`${url}/users/login`, {...data});
    return decrypt(response.data);
  } catch (error) {
    return null
  }
}

export const adminCodeStatistics = async (params) => {
  try {
    const response = await axios.get(`${url}/accessCodes/statistics`, {params});
    return decrypt(response.data);
  } catch (error) {
    throw new Error('Error fetching quiz');
  }
}

export const getCodesWithFilter = async (params) => {
  try {
    const response = await axios.get(`${url}/accessCodes/filtered`, {params});
    return decrypt(response.data);
  } catch (error) {
    throw new Error('Error fetching quiz');
  }
}




export const createAccessCodeRequest = async (data) => {
  try {
    const response = await axios.post(`${url}/accessCodes`, {...data});
    return decrypt(response.data);
  } catch (error) {
    return null
  }
}

export const deleteAccessCodeRequest = async (id) => {
  try {
    const response = await axios.delete(`${url}/accessCodes/${id}`);
    return decrypt(response.data);
  } catch (error) {
    return null
  }
}

export const updateAccessCodeRequest = async (id, data) => {
  try {
    const response = await axios.put(`${url}/accessCodes/${id}`, {...data});
    return decrypt(response.data);
  } catch (error) {
    return null
  }
}

export const fetchFilteredQuestions = async (params) => {
  try {
    const response = await axios.get(`${url}/questions`, {params});
    return decrypt(response.data);
  } catch (error) {
    return []
  }
}

export const updateQuestionRequest = async (id, data) => {
  try {
    const response = await axios.put(`${url}/questions/${id}`, {...data});
    return decrypt(response.data);
  } catch (error) {
    return []
  }
}

export const deleteQuestionRequest = async (id) => {
  try {
    const response = await axios.delete(`${url}/questions/${id}`);
    return decrypt(response.data);
  } catch (error) {
    return []
  }
}