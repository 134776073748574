import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFilteredQuestions } from '../helper/requests';

export const fetchFilteredQuestionsAsync = createAsyncThunk(
    'dashboard/fetchFilteredQuestions',
    async (data) => {
        return await fetchFilteredQuestions(data);
    }
);

const initialState = {
    data: {
        page: 1,
        limit: 10,
        totalRecords: null,
        totalPages: null,
        questions: []
    },
    question: null,
    status: 'idle',
    error: null
};

const questionSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setSelectedQuestion: (state, action) => {
            state.question = action.payload
        }
    },
    extraReducers: builder => {
        builder
        .addCase(fetchFilteredQuestionsAsync.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(fetchFilteredQuestionsAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
        })
        .addCase(fetchFilteredQuestionsAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export const { 
    setSelectedQuestion
} = questionSlice.actions;

export const selectQuestionsData = (state) => state.questions.data;
export const selectQuestion = (state) => state.questions.question;
export const selectStatus = (state) => state.questions.status;
export const selectError = (state) => state.questions.error;

export default questionSlice.reducer;
