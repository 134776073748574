// src/redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

let adminAccess = localStorage.getItem("adminAccess")
if(adminAccess){
    adminAccess = JSON.parse(adminAccess);
    if(adminAccess.token){
        axios.defaults.headers.common['Authorization'] = `Bearer ${adminAccess.token}`;
    }else{
        localStorage.removeItem("adminAccess")
    }
}else{
    adminAccess = null
}

const initialState = {
    admin: adminAccess,
    user: null,
    users: []
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setAdmin: (state, action) => {
            state.admin = action.payload;
        },
        clearUser: (state) => {
            state.user = null;
        },
    },
});

export const { setUser, setUsers, setAdmin, clearUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectAdmin = (state) => state.user.admin;
export const selectUsers = (state) => state.user.users;
export default userSlice.reducer;
