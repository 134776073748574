// src/redux/reducers.js
import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import quizSelectionSlice from './quizSelectionSlice';
import quizSlice from './quizSlice';
import quizSliceNew from './quizSliceNew';
import codeSlice from './codeSlice';
import dashboardSlice from './dashboardSlice';
import questionSlice from './questionSlice';

const rootReducer = combineReducers({
    // Add other reducers here
    code: codeSlice,
    user: userSlice,
    quizSelection: quizSelectionSlice,
    dashboard: dashboardSlice,
    quiz: quizSlice,
    quizNew: quizSliceNew,
    questions: questionSlice,
});

export default rootReducer;
