import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Input, Button, Alert, Row, Col, FormFeedback } from 'reactstrap';
import logo from '../assets/images/cmmc-logo.webp'
import { decrypt, validateCode } from '../helper/requests';
import { useDispatch } from 'react-redux';
import { setAccessData } from '../redux/codeSlice';
import axios from 'axios';



const PasscodeEntry = () => {
    const [passcode, setPasscode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passcode.trim() === '') {
            setError('Passcode is required');
            return;
        }

        let access = await validateCode(passcode.trim())
        access = decrypt(access)
        if(access.type){
            console.log(access)
            axios.defaults.headers.common['Authorization'] = `Bearer ${access.token}`;
            localStorage.setItem("access", JSON.stringify(access))
            dispatch(setAccessData(access))
            navigate('/')
        }else{
            setError('Invalid code. Please enter a valid code.');
            return;
        }
    };

    useEffect(()=>{
        setError('')
    }, [passcode])

    return (
        <Container fluid>
            <Row>
                <Col className='xy-center' style={{minHeight: '100vh', minWidth: '100vw'}}>
                    <div className='p-4' style={{maxWidth: '400px', width: '100%', border: '1px dashed var(--primary)', borderRadius: '6px'}}>
                        <div className='text-center mb-5'>
                            <img src={logo} width='120px'/>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="passcode">Passcode</Label>
                                <Input
                                    type="text"
                                    name="passcode"
                                    id="passcode"
                                    placeholder="Enter passcode"
                                    value={passcode}
                                    onChange={(e) => setPasscode(e.target.value)}
                                    invalid={error.length}
                                />
                                <FormFeedback style={{minHeight: '21px', height: '21px', visibility: error.length ? 'visible': 'hidden', display: 'block'}}>{error}</FormFeedback>
                            </FormGroup>
                            <Button className='w-100 theme-button' style={{marginTop: '-1rem'}} type="submit">Validate</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PasscodeEntry;
