import React from 'react';
import ReactEcharts from 'echarts-for-react';

function GaugeGraph({ data }) {
    // Calculate the percentage of correct answers
    const totalQuestions = Object.values(data).reduce((acc, curr) => acc + curr.total, 0);
    const totalCorrect = Object.values(data).reduce((acc, curr) => acc + curr.correct, 0);
    const correctPercentage = totalQuestions === 0 ? 0 : totalCorrect / totalQuestions;

    // Define options for the echarts graph
    const option = {
        title: {
            text: 'Percentage Accuracy',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '75%'],
                radius: '80%',
                min: 0,
                max: 1,
                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        width: 6,
                        color: [
                            [0.5, '#FF6E76'],
                            [0.8, '#f9c858'],
                            [1, '#90cd75'],
                        ],
                    },
                },
                pointer: {
                    icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                    length: '12%',
                    width: 20,
                    offsetCenter: [0, '-60%'],
                    itemStyle: {
                        color: 'auto',
                    },
                },
                axisTick: {
                    length: 12,
                    lineStyle: {
                        color: 'auto',
                        width: 2,
                    },
                },
                splitLine: {
                    length: 20,
                    lineStyle: {
                        color: 'auto',
                        width: 5,
                    },
                },
                axisLabel: {
                    color: '#464646',
                    fontSize: 20,
                    distance: -60,
                    rotate: 'tangential',
                    formatter: function (value) {
                        // if (value === 0.200) {
                        //     return 'Bad';
                        // } else if (value === 0.600) {
                        //     return 'Poor';
                        // } else if (value === 0.900) {
                        //     return 'Good';
                        // }
                        return (value*100).toFixed(0);
                    }
                },
                title: {
                    offsetCenter: [0, '-10%'],
                    fontSize: 20,
                },
                detail: {
                    fontSize: 30,
                    offsetCenter: [0, '-35%'],
                    valueAnimation: true,
                    formatter: (value) => Math.round(value * 100) + '',
                    color: 'inherit',
                },
                data: [
                    {
                        value: correctPercentage,
                        name: 'Score',
                    },
                ],
            },
        ],
    };

    return (
        <div className='border rounded border-2'>
            <ReactEcharts option={option} style={{ height: '300px' }} />
        </div>
    )
}

export default GaugeGraph;
