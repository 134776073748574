import React, { useState, useEffect } from 'react';

const TimeCounter = () => {
  let startingTimestamp = sessionStorage.getItem('startingTime')
  if(startingTimestamp){
    startingTimestamp = parseInt(startingTimestamp)
  }else{
    startingTimestamp = new Date()
    sessionStorage.setItem('startingTime', startingTimestamp.getTime())
  }
  const [startTime, setStartTime] =  useState(startingTimestamp);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeElapsed, setTimeElapsed] = useState("00:00:00:00");
  // Function to calculate time difference
  const calculateTimeDifference = () => {
    const difference = currentTime - startTime;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    // Update current time every second
    const interval = setInterval(() => {
      setTimeElapsed(calculateTimeDifference())
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [currentTime]);

  return (
    <div>
      Time Elapsed: {timeElapsed}
    </div>
  );
};

export default TimeCounter;
