import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Container, Row, Col, Navbar, NavbarBrand, NavbarText, Button, ButtonGroup, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectMetaData } from '../../redux/codeSlice';
import { fetchDashboardAsync, selectDashboard } from '../../redux/dashboardSlice';
import { useNavigate } from 'react-router-dom';
import DailyProgress from '../graphs/DailyProgress';
import GaugeGraph from '../graphs/GuageGraph';
import RadarChart from '../graphs/RadarChart';
import SankeyDiagram from '../graphs/SankeyDiagram';
import StackBarChart from '../graphs/StackBarChart';

const Performance = () => {
    const metaData = useSelector(selectMetaData)
    const dashboard = useSelector(selectDashboard)
    const navigate = useNavigate();
    const [filter, setFilter] = useState("all-questions")
    const dispatch = useDispatch()
    console.log(dashboard)
    console.log(metaData)
  
    useEffect(() => {
        if(metaData && metaData.code){
            const code = metaData.code
            const subject = metaData.subject
            dispatch(fetchDashboardAsync({code, subject, filter}));
        }
    }, [dispatch, metaData, filter]);

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <p className='m-0 p-0'><strong>Showing progress for</strong></p>
                    <ButtonGroup className="my-2">
                        {
                            [
                                {key: "all-questions", value: "All Questions"}, 
                                {key: "last-100-questions", value:"Last 100 Questions"}, 
                                {key:"last-quiz", value:"Last Quiz"},
                                {key:"last-week", value:"Last Week"},
                                {key:"last-month", value:"Last Month"},
                            ].map(item=>{
                                return (
                                    <Button color='dark' outline active={item.key == filter} onClick={()=>setFilter(item.key)}>{item.value}</Button>
                                )
                            })
                        }
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className='mb-4'>
                        <Col>
                            {
                                dashboard && dashboard.progress &&
                                <DailyProgress data={dashboard.progress}/>
                            }
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col>
                            {
                                dashboard && dashboard.domainTypeData &&
                                <SankeyDiagram domainTypeData={dashboard.domainTypeData} domains={dashboard.domains}/>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className='mb-4'>
                        <Col>
                            {
                                dashboard && dashboard.progress &&
                                <GaugeGraph data={dashboard.progress}/>
                            }
                        </Col>
                        <Col>
                            {
                                dashboard && dashboard.domainData &&
                                <RadarChart domainData={dashboard.domainData} domains={dashboard.domains}/>
                            }
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col>
                            {
                                dashboard && dashboard.progress &&
                                <StackBarChart domainTypeData={dashboard.domainTypeData} domains={dashboard.domains}/>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Performance;
