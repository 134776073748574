import React from 'react';
import ReactECharts from 'echarts-for-react';

const SankeyDiagram = ({ domains, domainTypeData, border=true }) => {
    let domainNames = []
    const links = [];
    domains.forEach((domain, index) => {
        let shortName = "Domain "+ (index+1)
        const domainData = domainTypeData[domain];
        if (domainData) {
            if(domainNames.indexOf(shortName) === -1){
                domainNames.push(shortName)
            }
            Object.keys(domainData).forEach(questionType => {
                let alternateName = questionType == "MCQ" ? "MCQs" : (questionType  == "TRUEFALSE" ? "TFQs" : questionType  == "OPENENDED" ? "OEQs" : questionType  == "SCENARIO-FOLLOWUPS" ? "SQs" : "MCQs");
                if(domainNames.indexOf(alternateName) == -1){
                    domainNames.push(alternateName)
                }
                links.push({
                    source: shortName,
                    target: alternateName,
                    value: domainData[questionType].correct,
                    lineStyle: {
                      color: "green"
                    },
                    label:{
                        name: "Correct",
                        show: true
                    }
                });
                links.push({
                    source: shortName,
                    target: alternateName,
                    value: domainData[questionType].incorrect,
                    lineStyle: {
                      color: "red"
                    },
                    label:{
                        name: "Incorrect",
                        show: true
                    }
                });
            });
        }
    });

    let colors = {
        'MCQs':'#485FB5',
        'TFQs':'#009DD9',
        'OEQs':'#6A5286',
        'SQs':'#F07F09'
    }
    const nodes = domainNames.map(domain => ({ name: domain, itemStyle: {color: colors[domain], borderColor: colors[domain]} }));
    
    const option = {
        title: {
            text: "Question Type Volume Breakdown by Domain and Correctness Flow",
            position: "left",
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        // tooltip: {
        //   trigger: 'item',
        //   triggerOn: 'mousemove'
        // },
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                console.log(params)
                if (params.dataType === 'edge') {
                    let name = params.data.source;
                    let parts = params.name.split(" ")
                    if(parts.length >= 2){
                        name = name + ': '+ domains[parseInt(parts[1])-1]
                    }
                    // Customize tooltip content for links
                    // return 'Source: ' + params.data.source + '<br>' +
                    //        'Target: ' + params.data.target + '<br>' +
                    //        'Value: ' + params.data.value;

                    return `<div style="color: ${params.data.lineStyle.color}">${name} -- ${params.data.target} <br/> ${params.data.label.name}: ${params.data.value}</div>`;
                } else {
                    // Customize tooltip content for nodes
                    let name = params.name;
                    let parts = params.name.split(" ")
                    if(parts.length >= 2){
                        name = name + ': '+ domains[parseInt(parts[1])-1]
                    }
                    return `${name}: ${params.value}`;
                }
            }
        },
        height: 250,
        series: {
            top: 30,
            type: 'sankey',
            layout: 'none',
            emphasis: {
                focus: 'adjacency'
            },
            data: nodes,
            links: links
        }
    };

    return (
        <div className={border?'border rounded border-2': ''}>
            <ReactECharts option={option} style={{ height: '300px' }} />
        </div>
    )
};

export default SankeyDiagram;
