import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Input, Button, FormFeedback, Row, Col, Alert } from 'reactstrap';
import logo from '../assets/images/cmmc-logo.webp'
import { createUserRequest } from '../helper/requests';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';

const UserInfo = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [alert, setAlert] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlert('')
        // Add validation logic here if needed
        let flag = false
        if (firstName.trim() === '') {
            setFirstNameError('First name is required');
            flag = true;
        }
        if (lastName.trim() === '') {
            setLastNameError('Last name is required');
            flag = true;
        }
        if (email.trim() === '') {
            setEmailError('Email is required');
            flag = true;
        }

        if(flag){
            return
        }

        let user = await createUserRequest({firstName, lastName, email})
        if(user){
            dispatch(setUser(user))
            navigate('/quiz-list')
        }else{
            setAlert('Somthing went wrong. Try again!')
        }
    };

    useEffect(()=>{
        setFirstNameError('')
    }, [firstName])

    useEffect(()=>{
        setLastNameError('')
    }, [lastName])

    useEffect(()=>{
        setEmailError('')
    }, [email])

    return (
        <Container>
            <Row>
                <Col className='xy-center' style={{minHeight: '100vh', minWidth: '100vw'}}>
                    <div className='p-4' style={{maxWidth: '400px', width: '100%', border: '1px dashed var(--primary)', borderRadius: '6px'}}>
                        <div className='text-center mb-5'>
                            <img src={logo} width='120px'/>
                        </div>
                        {
                            alert.length > 0 && <Alert color='danger'>{alert}</Alert>
                        }
                        <Form onSubmit={handleSubmit}>
                            <FormGroup className='mb-0'>
                                <Label for="firstName">First Name</Label>
                                <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="Enter first name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    invalid={firstNameError.length}
                                />
                                <FormFeedback style={{minHeight: '21px', height: '21px', visibility: firstNameError.length ? 'visible': 'hidden', display: 'block'}}>{firstNameError}</FormFeedback>
                            </FormGroup>
                            <FormGroup className='mb-0' style={{marginTop: '-1rem'}}>
                                <Label for="lastName">Last Name</Label>
                                <Input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="Enter last name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    invalid={lastNameError.length}
                                />
                                <FormFeedback style={{minHeight: '21px', height: '21px', visibility: lastNameError.length ? 'visible': 'hidden', display: 'block'}}>{lastNameError}</FormFeedback>
                            </FormGroup>
                            <FormGroup className='mb-0' style={{marginTop: '-1rem'}}>
                                <Label for="email">Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    invalid={emailError.length}
                                />
                                <FormFeedback style={{minHeight: '21px', height: '21px', visibility: emailError.length ? 'visible': 'hidden', display: 'block'}}>{emailError}</FormFeedback>
                            </FormGroup>
                            <Button className='w-100 theme-button' style={{marginTop: '-1rem'}} type="submit">Submit</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default UserInfo;
