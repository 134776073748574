import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Card, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import SankeyDiagram from '../graphs/SankeyDiagram';

// Overall Performance Chart
function OverallPerformanceChart({ historical, current }) {
    const historicalTotalQuizzes = Object.keys(historical.quizData).length;
    const currentTotalQuizzes = Object.keys(current.quizData).length;

    const historicalTotalQuestions = Object.values(historical.quizData).reduce(
        (acc, curr) => acc + curr.total,
        0
    );
    const currentTotalQuestions = Object.values(current.quizData).reduce(
        (acc, curr) => acc + curr.total,
        0
    );

    const historicalTotalCorrect = Object.values(historical.quizData).reduce(
        (acc, curr) => acc + curr.correct,
        0
    );
    const currentTotalCorrect = Object.values(current.quizData).reduce(
        (acc, curr) => acc + curr.correct,
        0
    );

    const historicalTotalIncorrect = Object.values(historical.quizData).reduce(
        (acc, curr) => acc + curr.incorrect,
        0
    );
    const currentTotalIncorrect = Object.values(current.quizData).reduce(
        (acc, curr) => acc + curr.incorrect,
        0
    );

    const historicalAccuracy = historicalTotalQuestions
        ? ((historicalTotalCorrect / historicalTotalQuestions) * 100).toFixed(0)
        : 0;
    const currentAccuracy = currentTotalQuestions
        ? ((currentTotalCorrect / currentTotalQuestions) * 100).toFixed(0)
        : 0;

    const historicalInaccuracy = historicalTotalQuestions
        ? ((historicalTotalIncorrect / historicalTotalQuestions) * 100).toFixed(0)
        : 0;
    const currentInaccuracy = currentTotalQuestions
        ? ((currentTotalIncorrect / currentTotalQuestions) * 100).toFixed(0)
        : 0;

    const option = {
        title: {
            text: 'Performance: Current Vs Average',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            data: ['Previous Average', 'Current Score'],
            bottom: 10,
        },
        radar: {
            radius: '65%',
            indicator: [
                { name: 'Avg Questions', max: Math.max(Math.ceil(historicalTotalQuestions/historicalTotalQuizzes), currentTotalQuestions) },
                { name: 'Incorrect (%)', max: 100 },
                { name: 'Correct (%)', max: 100 },
            ],
        },
        series: [
            {
                name: '',
                type: 'radar',
                data: [
                    {
                        value: [Math.ceil(historicalTotalQuestions/historicalTotalQuizzes), historicalInaccuracy, historicalAccuracy],
                        name: 'Previous Average',
                    },
                    {
                        value: [currentTotalQuestions, currentInaccuracy, currentAccuracy],
                        name: 'Current Score',
                    },
                ],
            },
        ],
    };

    return <ReactEcharts option={option} style={{ height: '300px' }} />;
}

// Domain-wise Performance Comparison Chart
function DomainPerformanceChart({ historical, current }) {
    const domains = historical.domains;

    const historicalData = domains.map((domain) => ({
        name: domain,
        historicalTotal: historical.domainData[domain] ? historical.domainData[domain].total : 0,
        historicalCorrect: historical.domainData[domain] ? historical.domainData[domain].correct : 0,
    }));

    const currentData = domains.map((domain) => ({
        name: domain,
        currentTotal: current.domainData[domain] ? current.domainData[domain].total : 0,
        currentCorrect: current.domainData[domain] ? current.domainData[domain].correct : 0,
    }));

    const option = {
        title: {
            text: 'Domain-wise Performance Comparison',
            position: "left",
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        toolbox: {
            show: true,
            feature: {
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: function(val){
                let domainIndex = parseInt(val[0].name.split(" ")[1]) -1
                if(val.length === 1){
                    return `
                        Domain ${domainIndex+1}: ${domains[domainIndex]} <br/>
                        <span style="display:inline-block;width:200px; color: ${val[0].color};">${val[0].seriesName}:</span> ${val[0].data}%
                    `
                }else if(val.length === 2){
                    return `
                        Domain ${domainIndex+1}: ${domains[domainIndex]} <br/>
                        <span style="display:inline-block;width:200px; color: ${val[0].color};">${val[0].seriesName}:</span> ${val[0].data}%<br/>
                        <span style="display:inline-block;width:200px; color: ${val[1].color};">${val[1].seriesName}:</span>  ${val[1].data}%
                    `
                }else{
                    return ''
                }
            },
        },
        legend: {
            data: ['Historical Accuracy', 'Current Accuracy'],
            bottom: 10,
        },
        grid: {
            bottom: 100
        },
        xAxis: {
            type: 'category',
            data: domains.map((domain, index)=> "Domain "+(index+1)),
            axisLabel: {
                rotate: 65, // Rotate the label by 90 degrees
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
            },
        },
        series: [
            // {
            //     name: 'Historical Total',
            //     type: 'bar',
            //     stack: 'total',
            //     data: historicalData.map((item) => item.historicalTotal),
            // },
            {
                name: 'Historical Accuracy',
                type: 'line',
                smooth: true,
                // stack: 'total',
                data: historicalData.map((item) => item.historicalTotal ? (100*item.historicalCorrect/item.historicalTotal).toFixed(1) : 0),
            },
            // {
            //     name: 'Current Total',
            //     type: 'bar',
            //     stack: 'total',
            //     data: currentData.map((item) => item.currentTotal),
            // },
            {
                name: 'Current Accuracy',
                type: 'bar',
                // stack: 'total',
                data: currentData.map((item) => item.currentTotal ? (100*item.currentCorrect/item.currentTotal).toFixed(1) : 0),
            },
        ],
    };

    return <ReactEcharts option={option} style={{ height: '300px' }} />;
}

// Question Type-wise Performance Comparison Chart
function QuestionTypePerformanceChart({ historical, current }) {
    const historicalMCQ = historical.typeData.MCQ ? historical.typeData.MCQ.total : 0;
    const historicalTRUEFALSE = historical.typeData.TRUEFALSE ? historical.typeData.TRUEFALSE.total : 0;

    const currentMCQ = current.typeData.MCQ ? current.typeData.MCQ.total : 0;
    const currentTRUEFALSE = current.typeData.TRUEFALSE ? current.typeData.TRUEFALSE.total : 0;

    const option = {
        title: {
            text: 'Question Type-wise Performance Comparison',
            left: 'center',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            data: ['Historical', 'Current'],
            bottom: 10,
        },
        series: [
            {
                name: 'Performance',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)',
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '16',
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: true,
                },
                data: [
                    { value: historicalMCQ, name: 'MCQ' },
                    { value: historicalTRUEFALSE, name: 'TRUEFALSE' },
                    // { value: currentMCQ, name: 'MCQ' },
                    // { value: currentTRUEFALSE, name: 'TRUEFALSE' },
                ],
            },
        ],
    };

    return <ReactEcharts option={option} style={{ height: '400px' }} />;
}

// Accuracy Trend Over Time Chart
function AccuracyTrendChart({ historical, current }) {
    var progress = JSON.parse(JSON.stringify({...historical.progress}))
    Object.keys(current.progress).map((date)=>{
        if(!progress[date]){
            progress[date] = {total: 0, correct: 0, incorrect: 0, recommended: 0}
        }
        console.log(JSON.stringify(progress[date]), JSON.stringify(current.progress[date]))
        progress[date].total += current.progress[date].total;
        progress[date].correct += current.progress[date].correct;
        progress[date].incorrect += current.progress[date].incorrect;
        progress[date].recommended += current.progress[date].recommended;
    })
    const dates = Object.keys(progress);
    const totalData = [];
    const accuracyData = dates.map((date) => {
        const data = progress[date];
        const total = data.total;
        const correct = data.correct;
        const accuracy = total ? ((correct / total) * 100).toFixed(1) : 0;
        totalData.push({date, total})
        return {
            date,
            accuracy,
        };
    });

    const option = {
        title: {
            text: 'Accuracy Trend Over Time',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'axis',
            // formatter: '{b}: {c}%',
            formatter: function(params){
                console.log(params);
                return `
                    <strong>${params[0].name}</strong><br>
                    <span style="color: ${params[0].color}">Accuracy: ${params[0].value}%</span><br>
                    <span style="color: ${params[1].color}">Total: ${params[1].value}</span><br>
                `
            },
        },
        legend: {
            data: ['Accuracy (%)', 'Questions Attempted'],
            bottom: 10,
        },
        grid: {
            bottom: 100
        },
        xAxis: {
            type: 'category',
            data: dates,
            axisLabel: {
                rotate: 65, // Rotate the label by 90 degrees
            }
        },
        yAxis: [
            {
                type: 'value',
                name: 'Accuracy (%)',
                axisLabel: {
                  formatter: '{value}'
                }
            },
            {
              type: 'value',
              name: 'Questions Attempted', // Label for right y-axis
              position: 'right',
              axisLabel: {
                formatter: '{value}'
              }
            }
        ],
        series: [
            {
                name: 'Accuracy (%)',
                data: accuracyData.map((item) => item.accuracy),
                type: 'line',
                smooth: true,
                yAxisIndex: 0 
            },
            {
                name: 'Questions Attempted',
                data: totalData.map((item) => item.total),
                type: 'bar',
                barWidth: 20,
                yAxisIndex: 1
            },
        ],
    };

    return <ReactEcharts option={option} style={{ height: '300px' }} />;
}


const PercentageResultDonut = ({current}) => {
    let quizData = Object.keys(current.quizData).map(key => {
        return current.quizData[key]
    })[0]
    
    const option = {
        title: {
            text: 'Quiz Performance %',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function(params){
                console.log(params)
                return `${params.name}: ${params.value}%`
            }
        },
        legend: {
            bottom: 10,
        },
        series: [
            {
                name: 'Quiz Results %',
                type: 'pie',
                radius: ['45%', '65%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter: '{c}'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    {

                        value: quizData ? quizData.total ? (100*quizData.correct/quizData.total).toFixed(1): 0 : 0, 
                        name: 'Correct',
                        itemStyle: { color: '#90cd75' }
                    },
                    {
                        value: quizData ? quizData.total ? (100*quizData.incorrect/quizData.total).toFixed(1): 0 : 0, 
                        name: 'Incorrect',
                        itemStyle: { color: '#FF6E76' }
                    }
                ]
            }
        ]
    };

    return <ReactEcharts option={option} style={{ height: 300 }} />;
};

const TotalsResultDonut = ({current}) => {
    let quizData = Object.keys(current.quizData).map(key => {
        return current.quizData[key]
    })[0]
    
    const option = {
        title: {
            text: 'Questions Attempted',
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function(params){
                console.log(params)
                return `${params.name}: ${params.value}`
            }
        },
        legend: {
            bottom: 10,
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['45%', '65%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter: '{c}'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    {

                        value: quizData ? quizData.correct : 0, 
                        name: 'Correct',
                        itemStyle: { color: '#90cd75' }
                    },
                    {
                        value: quizData ? quizData.incorrect : 0, 
                        name: 'Incorrect',
                        itemStyle: { color: '#FF6E76' }
                    }
                ]
            }
        ]
    };

    return <ReactEcharts option={option} style={{ height: 300 }} />;
};


const PercentageResultDonutType = ({current, type}) => {
    let typeData = current.typeData[type]
    let names = {
        "MCQ": "MCQs",
        "TRUEFALSE": "TFQs",
        "OPENENDED":"OEQs",
        "SCENARIO-FOLLOWUPS":"SQs"
    }
    const option = {
        title: {
            text: `${names[type]} Performance %`,
            left: 'left',
            textStyle: {
                fontSize: 16,
                fontWeight: 700
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function(params){
                console.log(params)
                return `${params.name}: ${params.value}%`
            }
        },
        legend: {
            bottom: 10,
        },
        series: [
            {
                name: 'Quiz Results %',
                type: 'pie',
                radius: ['45%', '65%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter: '{c}'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    {

                        value: typeData ? typeData.total ? (100*typeData.correct/typeData.total).toFixed(1): 0: 0, 
                        name: 'Correct',
                        itemStyle: { color: '#90cd75' }
                    },
                    {
                        value: typeData? typeData.total ? (100*typeData.incorrect/typeData.total).toFixed(1): 0: 0, 
                        name: 'Incorrect',
                        itemStyle: { color: '#FF6E76' }
                    }
                ]
            }
        ]
    };

    return <ReactEcharts option={option} style={{ height: 300 }} />;
};

// Dashboard Component
function ResultGraphs({ result }) {
    const { historical, current } = result;

    return (
        <>
            <Row className='mb-4'>
                <Col md={2}>
                    <Card>
                        <PercentageResultDonut current={current}/>
                    </Card>
                </Col>
                <Col md={2}>
                    <Card>
                        <TotalsResultDonut current={current} />
                    </Card>
                </Col>
                {
                    Object.keys(current.typeData).map((key, index) => {
                        return (
                            <Col md={2} key={"type_dist_"+key}>
                                <Card>
                                    <PercentageResultDonutType current={current} type={key}/>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className='mb-4'>
                <Col sm={12} md={4} lg={4}>
                    <Card>
                        <OverallPerformanceChart historical={historical} current={current} />
                    </Card>
                </Col>

                <Col sm={12} md={8} lg={8}>
                    <Card>
                        {/* <QuestionTypePerformanceChart historical={historical} current={current} /> */}
                        <SankeyDiagram  domainTypeData={current.domainTypeData} domains={current.domains} border={false}/>
                    </Card>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col sm={6} md={6} lg={6}>
                    <Card>
                        <DomainPerformanceChart historical={historical} current={current} />
                    </Card>
                </Col>
                <Col sm={6} md={6} lg={6}>
                    <Card>
                        <AccuracyTrendChart historical={historical} current={current}/>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default ResultGraphs;
