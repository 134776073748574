import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Input, Button, FormFeedback, Row, Col, Alert } from 'reactstrap';
import logo from '../assets/images/cmmc-logo.webp'
import { selectAccess, selectAccessLevel, selectMetaData, selectSelectedDifficulty, selectSelectedQuestionQuantites, selectSelectedQuestionTypes, setQuestionsQuantity, setSelectedDifficulty, toggleSelectedQuestionType } from '../redux/codeSlice';
import { useDispatch, useSelector } from 'react-redux';
import ReactSlider from 'react-slider';

import styled from 'styled-components';
import { selectUser } from '../redux/userSlice';
import { getQuizSelection } from '../redux/quizSelectionSlice';
import { createCodeQuizRequest, createUserQuizRequest } from '../helper/requests';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 15px;
`;

const StyledThumb = styled.div`
    height: 15px;
    line-height: 15px;
    width: 30px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 7px;
    cursor: grab;
    font-size: 10px;
    outline: none;
`;

const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

const StyledTrack = styled.div`
    top: 6px;
    bottom: 6px;
    background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#ddd' : '#01395e')};
    border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const QuizCustomise = () => {
    
    const access = useSelector(selectAccess)
    const selectedDifficulty = useSelector(selectSelectedDifficulty)
    const selectedQuestionTypes = useSelector(selectSelectedQuestionTypes)
    const user = useSelector(selectUser)
    const metaData = useSelector(selectMetaData)
    const quizSelection = useSelector(getQuizSelection)
    const selectedQuestionQuantites = useSelector(selectSelectedQuestionQuantites)
    const accessLevel = useSelector(selectAccessLevel)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    if(!metaData){
        navigate('/login')
    }
    const handleSubmit = async(e) => {
        e.preventDefault();

        let requestData = {
            code: metaData.code,
            access: metaData.type,
            title: metaData.title,
            description: metaData.description,
            subject: metaData.subject,
            domain: metaData.domain,
            task: metaData.task,
            topic: metaData.topic,
            questionCount: metaData.questionCount,
            timeAllowed: metaData.timeAllowed,
            types: selectedQuestionTypes,
            difficulty: selectedDifficulty,
            accessLevel: accessLevel
        }
        console.log(requestData)
        let codeQuiz = await createCodeQuizRequest(requestData)
        if(codeQuiz){
            navigate(`/quiz/${codeQuiz.quizId}`)
        }else{
            console.log(requestData)
        }
    };

    const handleDifficultySelection = (difficulty) => {
        dispatch(setSelectedDifficulty(difficulty))
    }

    const handleQuestionTypesSelection = (type) => {
        dispatch(toggleSelectedQuestionType(type))
    }

    const setTotalQuestions = (type, value) => {
        console.log(type, value)
        dispatch(setQuestionsQuantity({type, value}))
    }

    return (
        <Container fluid>
            <Row>
                <Col className='xy-center' style={{minHeight: '100vh', minWidth: '100vw'}}>
                    <div className='p-4' style={{maxWidth: '450px', width: '100%', border: '1px dashed var(--primary)', borderRadius: '6px'}}>
                        <div className='text-center mb-5'>
                            <img src={logo} width='120px'/>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <p>
                                Press "Start Quiz" button when you are ready!
                            </p>
                            <Button className='w-100 theme-button mt-1' type="submit" disabled={!selectedDifficulty || !selectedQuestionTypes.length}>Start Quiz</Button>
                        </Form>
                        { false &&
                            <Form onSubmit={handleSubmit}>
                                <p><strong>Difficulty level</strong></p>
                                {
                                    access ? access.difficulty.options.map((option, optionIndex) => {
                                        return (
                                            <Row key={optionIndex}>
                                                <Col>
                                                    <FormGroup check>
                                                        <Label check>
                                                                <Input
                                                                    name={"radio"+optionIndex}
                                                                    type="radio"
                                                                    value={option.text}
                                                                    checked={selectedDifficulty == option.key}
                                                                    onChange={()=>handleDifficultySelection(option.key)}
                                                                    disabled={option.disabled}
                                                                />
                                                                {' '}
                                                                {option.text}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={4} style={{textAlign: 'right'}}>
                                                    {
                                                        option.disabled && <span style={{color: 'var(--primary)', cursor: 'pointer'}}>(Upgrade)</span>
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    }) :
                                    <p>Loading...</p>
                                }
                                <Row className='mt-5'>
                                    <Col>
                                        <p><strong>Question types</strong></p>
                                    </Col>
                                    {/* <Col sm={4} style={{textAlign: 'right'}}>
                                        <p><strong>Quantity</strong></p>
                                    </Col> */}
                                </Row>
                                {
                                    access ? access.questionTypes.options.map((option, optionIndex) => {
                                        return (
                                            <Row key={optionIndex}>
                                                <Col>
                                                    <FormGroup check>
                                                        <Label check style={{color: option.disabled? 'silver': 'black'}}>
                                                                <Input
                                                                    name={"checkbox"+optionIndex}
                                                                    type="checkbox"
                                                                    value={option.text}
                                                                    checked={selectedQuestionTypes.indexOf(option.key) != -1}
                                                                    onChange={()=>handleQuestionTypesSelection(option.key)}
                                                                    disabled={option.disabled}
                                                                />
                                                                {' '}
                                                                {option.text}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={4} style={{textAlign: 'right'}}>
                                                    {
                                                        option.disabled && <span style={{color: 'var(--primary)', cursor: 'pointer'}}>(Upgrade)</span>
                                                        // <Input
                                                        //     placeholder='Number of questions'
                                                        //     name={"radio"+optionIndex}
                                                        //     type="number"
                                                        //     size={"sm"}
                                                        //     min={0}
                                                        //     max={accessLevel.allowedQuantity}
                                                        //     value={option.text}
                                                        //     onChange={(event)=>console.log(event.target.value)}
                                                        // />
                                                        // <ReactSlider
                                                        //     className="horizontal-slider"
                                                        //     marks
                                                        //     markClassName="slider-mark"
                                                        //     min={0}
                                                        //     max={accessLevel.allowedQuantity}
                                                        //     thumbClassName="slider-thumb"
                                                        //     trackClassName="slider-track"
                                                        //     renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                                        // />
                                                        // selectedQuestionTypes.indexOf(option.key) != -1 ? <StyledSlider defaultValue={[ selectedQuestionQuantites[option.key] != undefined?selectedQuestionQuantites[option.key] : 25]} onChange={(value)=> setTotalQuestions(option.key, value)} renderTrack={Track} renderThumb={Thumb} />:''
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    }) :
                                    <p>Loading...</p>
                                }
                                    <Alert color="info" className="mt-3">
                                        {accessLevel.allowedQuantity == -1 ? 'You can attempt unlimited questions' : `You can only attempt ${accessLevel.allowedQuantity} questions per day`}
                                    </Alert>
                                <Button className='w-100 theme-button mt-1' type="submit" disabled={!selectedDifficulty || !selectedQuestionTypes.length}>Start Quiz</Button>
                            </Form>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default QuizCustomise;
