import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Card, CardBody, CardTitle, CardText, Row, Col, Navbar, NavbarBrand, CardFooter, Button } from 'reactstrap';
import { fetchListedTitlesAsync, setQuizSelection } from '../redux/quizSelectionSlice';
import logo from '../assets/images/cmmc-logo.webp'

function QuizSelection(){
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const listedTitles = useSelector(state => state.quizSelection.listedTitles);
    const status = useSelector(state => state.quizSelection.status);
    const error = useSelector(state => state.quizSelection.error);


    const selectedQuiz = (item) => {
        dispatch(setQuizSelection(item))
        navigate('/quiz-customize')
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchListedTitlesAsync());
        }
    }, [status, dispatch]);

    let content;

    if (status === 'loading') {
        content = <div>Loading...</div>;
    } else if (status === 'succeeded') {
        content = (
            <>
                <Navbar
                    className="my-2"
                    color="dark"
                    dark
                >
                    <NavbarBrand href="/">
                        <img src={logo} style={{height: 40, width: 40}}/> {' '}
                        CMMC Training Academy
                    </NavbarBrand>
                </Navbar>
                <Container>
                    {listedTitles.map(listedTitle => (
                        <Row key={listedTitle._id}>
                            <Col md={3}></Col>
                            <Col>
                                <Card key={listedTitle._id} className="mb-3">
                                    <CardBody>
                                        <CardTitle>{listedTitle.title}</CardTitle>
                                        {/* <CardText>{listedTitle.description}</CardText>
                                        <CardText>Duration: {listedTitle.timeAllowed}, Questions: {listedTitle.questionCount}</CardText> */}
                                    </CardBody>
                                    <CardFooter style={{textAlign: 'right'}}>
                                        {/* <Link to={`/quiz/${listedTitle._id}`} className="btn btn-sm btn-primary">Take This Quiz</Link> */}
                                        <Button className='theme-button' onClick={()=>selectedQuiz(listedTitle)}>Take This Quiz</Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                    ))}
                </Container>
            </>
        );
    } else if (status === 'failed') {
        content = <div>{error}</div>;
    }

    return (
        <div>
            {content}
        </div>
    );
};

export default QuizSelection;
